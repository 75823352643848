(function($, arc) {
  arc.vins = arc.vins || [];

  function Vin(options) {
    var self = this;
    this.options = options || {};

    if (!this.options.input) {
      this.options.input = {};
    }

    this.input = {
      $short: this.options.input.$short,
      $full: this.options.input.$full,
      $guestUserVin: this.options.input.$guestUserVin
    };

    if ((!this.input.$short ||
        !this.input.$full ||
        !this.input.$short.length) && !this.input.$guestUserVin) {
          return false;
        }

    this.$referenced = this.options.$referenced || $();
    this.input.$all = this.input.$short.add(this.input.$full).add(this.input.$guestUserVin);

    this.input.$short.add(this.input.$full).add(this.input.$guestUserVin).on('paste', function(event) {
      self.fullPaste.call(self, $(this), event);
    });

    this.copyBack();

    this.input.$all.on('keyup', function(event) {
      self.enter.call(self, $(this), event);
    });

    return this;
  }

    Vin.prototype.copyBack = function() {
      var shortVal = this.input.$short.val(),
          fullVal = this.input.$full.val();

      if (shortVal) {
        this.setShort(shortVal);
        return;
      }

      if (fullVal) {
        this.setFull(fullVal);
        return;
      }
    };

  Vin.prototype.fullPaste = function($item, event) {
    var text = event.originalEvent.clipboardData ? event.originalEvent.clipboardData.getData('Text') : window.clipboardData.getData('Text');
    var trimText = text.replace(/\s/g,'');
    $item.data('paste', true);

    setTimeout(function() {
      if (!trimText.length) {
        $item.val('');
        return false;
      }
      $item.val(trimText);
      this.update($item);
      $item.data('paste', '');
    }.bind(this), 0);
  };

  Vin.prototype.enter = function($item, e) {
    var code = e.which || e.keyCode;

    // if code undefined, paste in session, code is space, tab, shift or cmd don't continue
    if ( $item.data('paste') == true || code === 32 || code === 16 || code === 9 || code === 91) {
      return false;
    }

    // set new value to vin
    this.update($item, code);
  };

  Vin.prototype.setDisabled = function($item, code) {
    if (this.$referenced.length) {
      this.$referenced.attr('disabled', 'disabled');
    }

    // disable all inputs
    this.input.$all.attr('disabled', 'disabled');
    this.getOppositeInputs($item).val('');

    // if delete or backspace remove disabled attrs if no chars left and dont continue
    if (code === 8 || code === 46) {
      var removeDisabled = true;

      $item.add($item.siblings('input.input-vin')).each(function(i, input) {
        // if any of these inputs have values don't toggle inputs
        if ($(input).val().length) {
          removeDisabled = false;
          return false;
        }
      });

      if (removeDisabled) {
        this.input.$all.removeAttr('disabled');

        if (this.$referenced.length) {
          this.$referenced.removeAttr('disabled');
        }
      }
    }

    // remove disabled on this input and other applicables
    $item
      .removeAttr('disabled').focus()
        .siblings('input.input-vin').removeAttr('disabled').end()
        .parent()
          .siblings('input.input-vin-full, input.input-vin-short').removeAttr('disabled').end()
          .end();
  };

  Vin.prototype.removeErrors = function() {
    this.input.$full.removeClass('input-error').siblings('.input-vin-error').remove();
    this.input.$short.removeClass('input-error').siblings('.input-vin-error').remove();
    this.input.$guestUserVin.removeClass('input-error').siblings('.input-vin-error').remove();
  };

  Vin.prototype.getOppositeInputs = function($item) {
    return this.input.$all
      .not($item)
      .not($item.siblings('input.input-vin'))
      .not($item.parent().siblings('input.input-vin-full, input.input-vin-short'));
  };

  // update only from the context of a vin input
  Vin.prototype.update = function($item, code) {
    this.removeErrors();
    this.setDisabled($item, code);

    if ($item.hasClass('input-vin')) {
      $item.parent().siblings('input.input-vin-short, input.input-vin-full').val(
        $item.parent().children().map(function() {
          var $currentInput = $(this);
          if ($currentInput.val()) {
            return $currentInput.val();
          }

          return $currentInput.is(':disabled') ? '' : ' ';
        }).get().join('')
      );
    }
  };

  // apply full input from a string of text
  Vin.prototype.setFull = function(vin) {
    this.input.$full.val(vin).trigger('keyup');
  };

  // apply short input from a string of text
  Vin.prototype.setShort = function(vin) {
    this.input.$short.val(vin).trigger('keyup');
  };

  function vin(options) {
    var vin = new Vin(options);
    arc.vins.push(vin);
    return vin;
  }

  $.extend(arc, {
    vin: vin
  });

})(jQuery, arc);
