(function($, arc) {
    arc.asBuilt = function($asBuilt, dialog) { return new AsBuilt($asBuilt, dialog); };

    // As Built tab constructor
    function AsBuilt ($asBuilt, dialog) {
        this.$asBuilt = $asBuilt;
        this.dialog = dialog;
        this.$table = this.$asBuilt.find('table').hide();
        this.$spinner = $('<div/>').addClass('soft-top soft-bottom align-center').append($('<div/>').addClass('loading-spin'));
        this.$error = this.$asBuilt.find('#as-built-error').hide();
        this.$asBuilt.append(this.$spinner);
        this.create();
    }

    // destroys the tab by cancelling the request if necessary
    AsBuilt.prototype.destroy = function() {
      if (this.request) this.request.abort();
      this.request = null;
    };

    // requests the data from service and creates table rows
    AsBuilt.prototype.create = function() {
      var thiz = this;
      this.request = $.get(arc.defaultContextPath + '/service/vehicle/hardware/asBuilt', function(res, textStatus, xhr) {
        thiz.$spinner.remove();
        if (xhr.status !== 200) {
          thiz.$error.show();
          return;
        }
        var $tbody = thiz.$table.find('tbody');
        $.each(res, function(index, row) {
          var $row = $('<tr/>', { role: 'row' });
          $tbody.append(
            $row.append(
              $('<td/>', { text: row.moduleName }),
              $('<td/>', { text: row.hardwareName }),
              $('<td/>', { text: row.softwareName })
            )
          );
        });
        thiz.show();
      });
    };

    // shows the table - if there are no rows from the service response then it shows empty data information
    AsBuilt.prototype.show = function() {
      if (this.$table.find('tbody > tr').length) {
       this.$table.show();
      } else {
       this.$asBuilt.append($('<span/>', { text: arc.i18n.get('vehicle.summary.asBuilt.empty') }));
      }
      this.dialog.resize.call(this.dialog);
    };
})(jQuery, arc);
