(function($, arc) {

  function Section($section, options) {
    if (!$section || !$section.length) {
      return false;
    }

    this.options = options || {};
    this.$window = $(window);
    this.$section = $section.addClass(this.options.sectionClasses || '');
    this.$header = this.$section.children('header');
    this.$content = this.$header.next();
    this.toggleable = this.options.toggleable || this.$section.hasClass('section-toggleable');
    this.toggleableInMobile = this.options.toggleableInMobile || this.$section.hasClass('section-toggleable');
    this.hideable = this.options.hideable || this.$section.hasClass('section-hideable');
    this.$actions = this.setActions();
    this.$titleAppend = $(this.options.$titleAppend || null);
    this.visible = typeof this.options.visible === 'function' ? (this.options.visible()) : (this.options.visible) || true;
    this.closed = (this.toggleable || this.toggleableInMobile) && this.options.closed || false;
    this.$toggleIcon = null;
    this.$hideIcon = null;

    if (!this.visible) {
      this.hide(true);
    } else {
      this.show(true);
      if (this.toggleable) {
        this.open();
      }
    }

    if (this.toggleable) {
      this.$section.addClass('section-toggleable');
      this.$toggleIcon = this.setToggle();
      this.$header.on('click', function(e) {
        this.toggle(e);
      }.bind(this));

      if (this.closed) {
        this.close(true);
      }
    }

    if (this.toggleableInMobile) {
      this.$section.addClass('section-toggleable section-toggleable-mobile');
      this.$toggleIcon = this.setToggle();

      this.$header.on('click.arc-section-toggleableInMobile', function(e) {
        if (this.$window.width() <= 1024) {
          this.toggle(e);
        }
      }.bind(this));

      this.$header.trigger('click.arc-section-toggleableInMobile');

      if (this.closed) {
        this.close();
      }
    }

    if (this.hideable) {
      this.$section.addClass('section-hideable');
      this.$hideIcon = this.setHide();
    }

    if (this.$titleAppend.length) {
      this.titleAppend();
    }

    // callback, manipulate the section
    if (typeof this.options.callback === 'function') {
      this.options.callback.call(this);
    }

    this.$section.data('instance', this);
  }

  Section.prototype.setActions = function() {
    var $actions = this.$header.children('.section-actions');

    if (!$actions.length) {
      // if closable force gen $actions
      if (this.closable || this.toggleable || this.toggleableInMobile) {
        $actions = $('<div/>').addClass('section-actions');
        return $actions.appendTo(this.$header);
      }

      // no $actions found
      return null;
    }

    return $actions;
  };

  Section.prototype.setToggle = function() {
    var $toggle = this.$actions.find('.section-toggle'),
        open = this.$section.hasClass('section-opened'),
        toggleClass = open ? 'fa-caret-up' : 'fa-caret-down',
        toggleRemoveClass = open ? 'fa-caret-down' : 'fa-caret-up';

    if (!$toggle.length) {
      $toggle = $('<i/>').addClass('fa');
      this.$actions.append($toggle);
    }

    $toggle.addClass('section-toggle ' + toggleClass).removeClass(toggleRemoveClass);

    if (this.options.toggleClasses) {
      $toggle.addClass(this.options.toggleClasses);
    }
    return $toggle;
  };

  Section.prototype.setHide = function() {
    var $hide = this.$actions.find('.section-close');

    if (!$hide.length) {
      $hide = $('<i/>').addClass('fa fa-close section-close');
      this.$actions.append($hide);
    }

    $hide.on('click', this.hide.bind(this));

    if (this.options.hideClasses) {
      $hide.addClass(this.options.hideClasses);
    }
    return $hide;
  };

  Section.prototype.hide = function(instant) {
    if (typeof this.options.shouldHide === 'function' && this.options.shouldHide() === false) {
      return false;
    }

    this.$section.fadeOut(instant ? 0 : 200, function() {
      this.visible = false;
      this.$section.removeClass('section-visible').addClass('section-invisible');

      if (typeof this.options.hide === 'function') {
        this.options.hide.call(this, instant);
      }
    }.bind(this));
  };

  Section.prototype.show = function(instant) {
    if (typeof this.options.shouldShow === 'function' && this.options.shouldShow() === false) {
      return false;
    }

    var show = function() {
      this.visible = true;
      this.$section.removeClass('section-invisible').addClass('section-visible');
      if (this.toggleable && !this.$section.hasClass('section-closed')) {
        this.$section.addClass('section-opened');
      }

      if (typeof this.options.show === 'function') {
        this.options.show.call(this, instant);
      }
    };

    if (this.$section.is(':visible')) {
      this.$section.show();
      show.call(this);
      return;
    }

    this.$section.fadeIn(instant ? 0 : 200, function() {
      show.call(this);
    }.bind(this));
  };

  Section.prototype.toggle = function(e) {
    if (e && (($(e.target).is('i') || $(e.target).is('a')) && !$(e.target).hasClass('section-toggle')) || typeof this.options.shouldToggle === 'function' && this.options.shouldToggle() === false) {
      return false;
    }

    if (this.$section.hasClass('section-closed')) {
      this.open(this);
    } else {
      this.close(this);
    }
  };

  Section.prototype.open = function() {
    if (typeof this.options.shouldOpen === 'function' && this.options.shouldOpen() === false) {
      return false;
    }

    if (!this.visible) {
      this.show(true);
    }

    var open = function() {
      if (this.$toggleIcon) {
        this.$toggleIcon.removeClass('fa-caret-down').addClass('fa-caret-up');
      }

      if (typeof this.options.open === 'function') {
        this.options.open.call(this);
      }
    };

    this.$section.addClass('section-opened').removeClass('section-closed');

    if (this.$content.is(':visible')) {
      this.$content.show();
      open.call(this);
      return;
    }

    this.$content.slideDown(200, function() {
      open.call(this);
    }.bind(this));
  };

  Section.prototype.close = function() {
    if (typeof this.options.shouldClose === 'function' && this.options.shouldClose() === false) {
      return false;
    }

    var close = function() {
      this.$section.addClass('section-closed').removeClass('section-opened');

      if (this.$toggleIcon) {
        this.$toggleIcon.removeClass('fa-caret-up').addClass('fa-caret-down');
      }

      if (typeof this.options.close === 'function') {
        this.options.close.call(this);
      }
    };

    if (!this.$content.is(':visible')) {
      this.$content.hide();
      close.call(this);
      return;
    }

    this.$content.slideUp(200, function() {
      close.call(this);
    }.bind(this));
  };

  Section.prototype.titleAppend = function() {
    if (this.$actions) {
      this.$titleAppend.insertBefore(this.$actions);
      return;
    }

    this.$header.append(this.$titleAppend);
  };

  function section($section, options) {
    return new Section($section, options);
  }

  $.extend(arc, {
    section: section
  });

  $.fn.section = function(options) {
    var instance = $(this).data('instance');
    if (instance && typeof options === 'string' && typeof instance[options] !== 'undefined') {
      instance[options]();
    }

    return new Section($(this), options);
  };
})(jQuery, arc);
