(function($, arc) {

  $(document).ready(function() {
    $('.accordion-init > span').parent().each(function(i, accordion) {
      new Accordion($(accordion));
    });
  });

  function Accordion($accordion, options) {
    var self = this;
    this.options = options || {};
    this.$accordion = $accordion;
    this.$wrapper = this.$accordion.children('ul').eq(0);
    this.$title = this.$accordion.children('span').eq(0);
    this.$selected = null;
    this.isEmpty = false;
    this.isSingular = this.options.isSingular || this.$accordion.parent().data('singular') || false;
    this.onSelect = typeof this.options.onSelect === 'function' ? this.options.onSelect : null;
    this.onDeselect = typeof this.options.onDeselect === 'function' ? this.options.onDeselect : null;

    if (!this.$wrapper.length || this.$wrapper.length && !this.$wrapper.children().length) {
      this.$accordion.addClass('accordion-empty');
      return this;
    }

    if (this.$accordion.hasClass('accordion-active') || this.$accordion.hasClass('active') || this.$wrapper.children('.accordion-active, .active').length) {
      this.$accordion.addClass('accordion-active accordion-parent').parentsUntil('li', '.accordions, .accordion');
      this.open(0);
    }

    // Old compatibility
    if (this.$wrapper.children('.accordion-active, .active').length) {
      this.$wrapper.children('.active').addClass('accordion-selected');
    }

    this.$wrapper.on('click', 'li', function(e) {
      self.toggleSelect($(this), e);
    });

    this.$title.on('click', this.toggle.bind(this));
    this.$accordion.data('context', this);
  }

  Accordion.prototype.toggle = function() {
    if (this.$accordion.hasClass('accordion-open')) {
      this.close();
      return;
    }

    this.open();

    if (this.isSingular) {
        this.closeOthers();
    }
  };

  Accordion.prototype.closeOthers = function() {
    this.$accordion.siblings().each(function(i, accordion) {
      var instance = $(accordion).data('context');
      if (instance) {
        instance.close();
      }
    }.bind(this));
  };

  Accordion.prototype.open = function(duration) {
    if (this.disabled) {
      return false;
    }

    this.$accordion.addClass('accordion-open');
    this.$wrapper.slideDown(duration);
  };

  Accordion.prototype.close = function() {
    if (this.disabled) {
      return false;
    }

    this.$accordion.removeClass('accordion-open');
    this.$wrapper.slideUp();
  };

  Accordion.prototype.toggleSelect = function($item, e) {
    if (this.$selected && this.$selected.get(0) === $item.get(0)) {
      this.deselect($item, e);
      return;
    }

    this.select($item, e);
  };

  Accordion.prototype.select = function($selectedItem, e) {
    if (this.disabled) {
      return false;
    }

    // deselect other items in accordion before selecting this one
    this.deselect();
    this.deselectOthers();
    this.$selected = $selectedItem || null;
    this.$accordion.addClass('accordion-active');
    $selectedItem.addClass('accordion-selected');

    if (!this.$accordion.hasClass('accordion-open')) {
      this.open(0);
    }

    // if callback and second param isn't false (to disable callback from running)
    if (this.onSelect && e !== false) {
      this.onSelect($selectedItem, e);
    }
  };

  Accordion.prototype.deselect = function($deselectedItem, e) {
    if (this.disabled) {
      return false;
    }

    this.$selected = null;
    var $item = $deselectedItem instanceof jQuery ? $deselectedItem : this.$accordion;
    $item.removeClass('accordion-selected').find('li.accordion-selected').removeClass('accordion-selected');
    this.$accordion.removeClass('accordion-active');

    if ($deselectedItem && this.onDeselect) {
      this.onDeselect($deselectedItem, e);
    }
  };

  Accordion.prototype.deselectOthers = function() {
    this.$accordion.siblings().each(function(i, accordion) {
      var instance = $(accordion).data('context');
      if (instance) {
        instance.deselect();
      }
    }.bind(this));
  };

  Accordion.prototype.enable = function($enabledItem, e) {
    this.disabled = true;
    var $item = $enabledItem instanceof jQuery ? $enabledItem : this.$accordion;
    $item.removeClass('accordion-disabled');
  };

  Accordion.prototype.disable = function($disabledItem, e) {
    this.disabled = true;
    var $item = $disabledItem instanceof jQuery ? $disabledItem : this.$accordion;
    $item.addClass('accordion-disabled');
  };

  Accordion.prototype.enableOthers = function() {
    this.$accordion.siblings().each(function(i, accordion) {
      var instance = $(accordion).data('context');
      if (instance) {
        instance.enable();
      }
    });
  };

  Accordion.prototype.disableOthers = function() {
    this.$accordion.siblings().each(function(i, accordion) {
      var instance = $(accordion).data('context');
      if (instance) {
        instance.disable();
      }
    });
  };

  function accordion($accordion) {
    return new Accordion($accordion);
  }

  $.fn.accordion = function(options) {
    return new Accordion($(this), options);
  };

  $.extend(arc, {
    accordions: accordion
  });

})(jQuery, arc);
