var PrintDtcs;
$(document).ready(function () {
    var distanceParameter = "km";

    PrintDtcs = (function ($, arc) {
        function PrintDtcs(parentContainer) {
            this.parentContainer = parentContainer;

            this.setupEvents();
        }

        PrintDtcs.prototype.setupEvents = function () {
            var thiz = this;

            thiz.parentContainer.on("printDtcs", function (e, options) {
                //will equal module object state array
                //which will be modified in createPrintDialogBody()
                //when item is checked or unchecked
                var modules = (function () {
                    var results = [];
                    $.each(options.dtcs, function (i, dtc) {
                        var match = $.grep(results, function (obj) {
                            return obj.moduleAcronym === dtc.moduleAcronym
                        });
                        if (!match.length) results.push({
                            moduleAcronym: dtc.moduleAcronym,
                            moduleAddress: dtc.moduleAddress,
                            checked: false
                        });
                    });

                    return results;
                })();

                var selectedModulesQueryString = function () {
                    var querystring = "&moduleAcronyms=";

                    var selectedModules = $.grep(modules, function (module) {
                        return module.checked;
                    });

                    $.each(selectedModules, function (i, module) {
                        var endString = i === selectedModules.length - 1;
                        querystring = querystring + module.moduleAcronym + (!endString ? "&moduleAcronyms=" : "");
                    });

                    return querystring === "&moduleAcronyms=" ? "" : querystring;
                };

                $i.dialog({
                    titleKey: "content.document.print.options.title",
                    body: thiz.createPrintDTCDialogBody(distanceParameter, modules),
                    closeable: false,
                    buttonKeys: {
                        'button.ok': {
                            classes: 'btn-primary',
                            callback: function () {
                                var mileageType = $('input[name="odometerUnit"]:checked').val();
                                var moduleAcronyms = selectedModulesQueryString();

                                window.open($i.contextPath + "/diagnostic/printDtc?odometerUnit=" + mileageType + moduleAcronyms, '_blank').focus();
                                this.destroy();
                            }
                        },
                        'button.cancel': function () {
                            this.destroy();
                        }
                    }
                });
            });
        };

        PrintDtcs.prototype.createPrintDTCDialogBody = function (distanceParameter, modules) {
            var modulesSelected = function () {
                return $.grep(modules, function (obj) {
                    return obj.checked === true
                });
            };

            var $dialogBody = $('<div class="row-fluid"></div>');
            var $odoSelection = $(
                '<div>' +
                '<input id="km" type="radio" name="odometerUnit" value="km" class="radio inline"' + (distanceParameter === "km" ? "checked" : "") + '>' +
                '<label class="printDialogLabel" for="km">' + arc.i18n.get("diagnostic.dtc.odometer.in.km") + '</label>' +
                '<input id="mi" type="radio" name="odometerUnit" value="mi" class="radio inline"' + (distanceParameter === "mi" ? "checked" : "") + '>' +
                '<label class="printDialogLabel" for="mi">' + arc.i18n.get("diagnostic.dtc.odometer.in.mi") + '</label>' +
                '</div>'
            );

            var $snapshotDataWrapper = $('<div></div>');
            var $checkAll = $(
                '<input type="checkbox" id="allSnapshotData" name="allSnapshotData" class="checkbox"' + (modulesSelected().length ? "checked" : "") + '>' +
                '<label for="allSnapshotData" class="push-half-bottom">' + arc.i18n.get("diagnostic.dtc.snapshot.data.modules") + '</label>'
            );
            var $moduleChecks = $.map(modules, function (module) {
                var $checkbox = $(
                    '<input class="checkbox moduleSelection" type="checkbox" id="' + module.moduleAcronym + '" name="' + module.moduleAcronym + '" value="' + module.moduleAcronym + '"' + (module.checked ? "checked" : "") + '>' +
                    '<label for="' + module.moduleAcronym + '">' + module.moduleAcronym + '</label>'
                );

                $checkbox.on("click", function () {
                    module.checked = $(this).prop("checked");
                    if (module.checked) $checkAll.prop("checked", true);
                    if (!modulesSelected().length) $checkAll.prop("checked", false);
                });

                return $checkbox;
            });

            $checkAll.on("click", function () {
                var checked = $(this).prop("checked");
                $($moduleChecks).each(function (i, check) {
                    $(check).prop('checked', checked);
                });

                $.each(modules, function (i, module) {
                    module.checked = checked;
                })
            });

            $snapshotDataWrapper.append($checkAll, $moduleChecks);

            return $dialogBody.append($odoSelection, $snapshotDataWrapper);
        }

        // expose method
        return {
            create: function (parentContainer) {
                return new PrintDtcs(parentContainer);
            }
        };

    })(jQuery, arc);
});
