(function($, arc) {
  $.extend(true, arc, {
    nested: Nested
  });

  function Nested($nested, options) {
      if (!$nested.length) {
          return false;
      }
      this.$nested = $nested;
      this.$nested.addClass('nested-loading');
      this.options = options || {};
      this.nested = null;
      this.options.$nested = this.$nested;
      this.options.root = this;
      this.filterActive = false;
      this.addPipes = options.addPipes;
      this.filterMatch = null;

      switch (this.$nested.prop('tagName')) {
          case 'TABLE': {
              this.nested = new NestedTable($nested, this.options);
              break;
          }
          case 'UL': {
              this.nested = new NestedList($nested, this.options);
              break;
          }
      }

      this.nodes = this.nested.nodes;

      if (!this.$nested.children().length) {
          return (this.$nested.remove());
      }

      this.$nested
          .data('instance', this);
      return this;
  }

  Nested.prototype.showAll = function() {
      var node;
      for (var key in this.nodes) {
          node = this.nodes[key];
          // if node is Ajax and node has no children we will ajax it
          if (node.isLoad && !node.$children && !node.isLast) {
              node.load(null, true);
              continue;
          }
          node.show();
      }
  };

  Nested.prototype.hideAll = function() {
      for (var node in this.nodes) {
          this.nodes[node].hide();
      }
  };

  Nested.prototype.unfilter = function() {
      if (!this.filterActive) {
          return false;
      }

      var unfiltered = [];
      this.filterActive = false;

      // getLastNodes, setUnfiltered on applicable nodes, concat this with every iteration
      $.each(this.getLastNodes(), function(i, node) {
          unfiltered.concat(this.unfilterSetUnfiltered(node, unfiltered));
      }.bind(this));

      // loop all nodes, is not unfiltered set to force hide otherwise show
      // reset isFiltered and isUnfiltered flags
      $.each(this.nodes, function(i, node) {
        if (!node.parent) {
          node.showActions();
        }

          if (!node.isUnfiltered) {
              node.hide(null, false, true);
          } else {
              node.show();
          }
          node.isFiltered = false;
          node.isUnfiltered = false;
      });

      // reset global scopes filter vars
      this.filterMatch = null;
  };

  // starting from last nodes, recursively go up the path and set .isUnfiltered where applicable
  Nested.prototype.unfilterSetUnfiltered = function(node, unfiltered) {
      unfiltered = unfiltered || [];
      var $siblings = null,
          siblingInstance,
          isUnfiltered = false;

      $.each(node.getPathUp(), function(i, pathNode) {
        this.unfilterEnableActions(pathNode);

          // if previous unfiltered nodes match this node then break
          if (unfiltered.length && $.inArray(pathNode.id, unfiltered) > -1) {
              return false;
          }

          $siblings = pathNode.getSiblings();

          // if child was already set as unfiltered in the previous loop or this node doesn't have .isUnfiltered
          // and if siblings exist
          if ((isUnfiltered || !pathNode.isUnfiltered) && $siblings !== null && $siblings.length) {
              $.each($siblings, function(i, siblingNode) {
                  siblingInstance = $(siblingNode).data('instance');

                  // if sibling is filtered and visible or previous looped child was already set as unfiltered
                  if(siblingInstance.isFiltered && siblingInstance.isVisible || isUnfiltered) {
                      siblingInstance.isUnfiltered = true;
                      isUnfiltered = true;
                  }
              });
          }

          // if this is filtered and visible or previous looped child was already set as unfiltered
          if (pathNode.isFiltered && pathNode.isVisible || isUnfiltered || pathNode.level === 1) {
              // set unfiltered, set flag for rest of upward pathNode
              isUnfiltered = true;
              pathNode.isUnfiltered = true;
          }

          // push onto unfiltered stack so we dont loop duplicate nodes
          unfiltered.push(pathNode.id);
      }.bind(this));

      return unfiltered;
  };

  Nested.prototype.unfilterEnableActions = function(node) {
    if (node.isFiltered) {
      var showActions = true;
      $.each(node.children, function(i, childNode) {
        if (this.filterNodeMatched(childNode)) {
          showActions = false;
        }
      }.bind(this));

      if (showActions) {
        node.showActions();
      }
    }
  };

  // pass an element or class or id (without . / #) to filter on
  // if element passed it can be the node or within the node
  Nested.prototype.filter = function(filter) {
      // if nested is already filtered unfilter first
      if (this.filterActive) {
          this.unfilter();
      }

      // set filter as active, assign nested vars
      this.filterActive = true;
      this.filterMatch = filter;

      // getLastNodes, set filtered where applicable
      $.each(this.getLastNodes(), function(i, lastNode) {
          this.filterSetFiltered(lastNode);
      }.bind(this));

      // loop all nodes, if isn't filtered force hide
      $.each(this.nodes, function(i, node) {
        if (!node.parent && !node.isFiltered) {
          node.hideActions();
        }

          if (!node.isFiltered) {
              node.hide(null, false, true);
              return true;
          }

          if (node.parent && node.parent.isLoad) {
              node.show();
          }
      });
  };

  Nested.prototype.filterSetFiltered = function(node) {
      // if node matched filter el or bypass given
      if (this.filterNodeMatched(node)) {
        this.filterDisableActions(node);
          // loop up tree, set all as isFiltered
          $.each(node.getPathUp(), function(i, pathNode) {
              pathNode.isFiltered = true;
          });
          // continue loop
          return true;
      }

      // assign false boolean rather than staying undefined
      $.each(node.getPathUp(), function(i, pathNode) {
          if (pathNode.isFiltered) {
              return false;
          }

          pathNode.isFiltered = false;
      });

      // recursively call this method until reached the top level
      if (node.parent) {
          this.filterSetFiltered(node.parent);
      }
  };

  Nested.prototype.filterNodeMatched = function(node) {
      // node has bypass class/id or node finds filter el or node is filter el then set as matched
      if (typeof this.filterMatch === 'string') {
          var identifier = this.filterMatch.charAt(0),
              isElement = false;

          if (identifier === '.' || identifier === '#') {
              isElement = true;
          }

          if (!isElement) {
              return node.$node.data('filter') === this.filterMatch || node.$node.attr('id') == this.filterMatch;
          }
      }

      return node.$node.find(this.filterMatch).length || node.$node.is(this.filterMatch);
  };

  Nested.prototype.filterDisableActions = function(node) {
    if (!node.isLoad) {
      var hideActions = true;
      $.each(node.children, function(i, childNode) {
        if (this.filterNodeMatched(childNode)) {
          hideActions = false;
          return false;
        }
      }.bind(this));

      if (hideActions) {
        node.hideActions();
      }
    }
  };

  Nested.prototype.getLastNodes = function() {
      var nodes = [];
      $.each(this.nodes, function() {
          // is last node in tree
          if (!this.$children) {
              nodes.push(this);
          }
      });
      return nodes;
  };

  // pass $nodes to assign new nodes to this.lookup
  // pass nothing to run buildLookup from all nodes (this.$nodes)
  // context is this.nested
  Nested.prototype.buildLookup = function(node, $nodes) {
      // if manualIds is a method then it should be called to setIds before lookup continues.
      if (this.manualIds === false && typeof this.customDataSetter === 'function') {
          this.customDataSetter(node, $nodes);
      }

          // if no nodes given run buildLookup on all nodes
      var $selector = $nodes ? $nodes : this.$nodes,
          // nodes given, add to existing this.lookup state
          collector = $nodes ? this.lookup : {},
          $node = null,
          parentId = null;

      // create object of parentId[] from $selector
      $selector.each(function(i, node) {
          $node = $(node);
          parentId = $node.data('parent');

          if (typeof parentId === 'undefined' || parentId === null) {
              return true;
          }

          if (collector[parentId]) {
              collector[parentId].push($node);
          } else {
              collector[parentId] = [$node];
          }
      }.bind(this));

      return collector;
  };

  function NestedList($nested, options) {
      var self = this;
      this.$nested = $nested;
      this.$content = this.$nested;
      this.root = options.root;
      this.title = null;
      this.nodes = {};
      this.$nodes = this.$content.find('li').not('li[data-title],li.nested-ignore');
      // Lists are nested, id's are never set manually.
      this.manualIds = false;
      this.lookup = this.root.buildLookup.call(this);
      this.showHeaderToggles = true;
      this.$header = this.getHeader();
      this.excludedClickedItems = ['.nested-disable-toggle', 'i', 'label', 'input', 'a'];
      this.nodeShowAll = options.nodeShowAll;

      if (options) {
          for (var option in options) {
              switch (option) {
                  case 'showHeaderToggles': {
                      this[option] = options[option];
                      break;
                  }
                  case 'title': {
                      // text is an element
                      if (typeof options[option] === 'object') {
                          this.title = options[option].text();
                          break;
                      }
                      // text is literal string
                      this.title = options[option];
                      break;
                  }
              }
          }
      }

      this.headerToggles = this.createHeaderToggles();
      this.showAll = this.root.showAll.bind(this);
      this.hideAll = this.root.hideAll.bind(this);

      if (this.headerToggles) {
          this.headerToggles.filter('#show-all').on('click', this.showAll);
          this.headerToggles.filter('#hide-all').on('click', this.hideAll);
      }

      this.$content.children('li:not([data-title]):not(.nested-ignore)').each(function(i, node) {
          this.buildNode($(node), null, true);
      }.bind(this));

      this.$content.on('click', 'li', function(e) {
          $(this).data('instance').toggle(e);
      });

      this.$nested.addClass('nested-visible').removeClass('nested-loading');
  }

  // sets data-id and data-parent values to all nodes unless $nodes given in param
  NestedList.prototype.customDataSetter = function(node, $nodes) {
      var nodesPreviousIndex = null,
          nodesPreviousLevel = null,
          $selector = $nodes,
          loopIndexCreator = function($node, index, previousIndex, previousLevel) {
              var $children = $node.find('> ul > li');
              $node.data('id', index);
              $node.data('level', previousLevel ? previousLevel + 1 : 1);

              if (typeof previousIndex !== 'undefined') {
                  $node.data('parent', previousIndex);
              }

              if ($children.length) {
                  $children.each(function(i, childNode) {
                      loopIndexCreator($(childNode), index + '-' + i, index, previousLevel + 1);
                  });
              }
          };

      if (!$nodes) {
          $selector = this.$content.children('li:not([data-title])');
      }

      // initiator
      $selector.each(function(i, pathNode) {
          var $node = $(pathNode);
          if ($nodes) {
              var $parent = $node.parents().eq(1);
              if ($parent.length && $parent.prop('tagName').toLowerCase() === 'li') {
                  return true;
              }

              nodesPreviousIndex = node.id;
              nodesPreviousLevel = node.level;
          }

          loopIndexCreator($node, nodesPreviousIndex !== null ? nodesPreviousIndex + '-' + i : i, nodesPreviousIndex, nodesPreviousLevel);
      });
  };

  // buildNode will create all the NestedNode instances
  // ~ $node - jquery Object (required) of an element you want to build
  // ~ parent - object (optional) the parent instance, must be valid unless it's a top level node
  // ~ shouldInsert - boolean (optional, default: false) Will insert into DOM, required if using ajax
  NestedList.prototype.buildNode = function($node, parent, inDOM) {
      var nodeLevel = String($node.data('id')).split('-').length + 1;
      if (nodeLevel > 10) {
          return false;
      }

      var $children = this.lookup[$node.data('id')],
          node = new NestedNode($node, {
              parent: parent || null,
              $children: $children && $children.length && nodeLevel != 10 ? $children : null,
              nested: this,
              inDOM: inDOM ? true : false
          });

      if (parent) {
          parent.children = parent.children || {};
          parent.children[node.id] = node;
      }

      // if children exist loop over them, pass it's node element and parent instance
      if ($children && $children.length) {
          $.each($children, function(i, child) {
              this.buildNode($(child), node, $node.data('id') + '-' + i, inDOM);
          }.bind(this));
      }
  };

  NestedList.prototype.createToggle = function(node) {
      if (!node.$children && !node.isLoad || node.isLast) {
          this.$toggle = null;
          return false;
      }

      var $toggle = node.$node.find('i.nested-toggle'),
          $actions = node.$node.find('.nested-actions'),
          toggleText = this.$nested.data('toggle-text');

      if (!$actions.length) {
          $actions = $('<span/>').addClass('nested-actions').appendTo(node.$node.children('p'));
      }

      if (toggleText) {
        $toggle.tooltipster({
          content: toggleText
        });
      }

      $toggle = node.isVisible ?
          '<i class="fa fa-plus-circle nested-toggle"></i>':
          '<i class="fa fa-minus-circle nested-toggle"></i>';

      $actions.append($toggle);
      node.$toggle = node.$node.find('i.nested-toggle');
      return node.$toggle;
  };

  NestedList.prototype.getHeader = function() {
      var $header = this.$nested.children('li[data-title]');

      if (!$header.length) {
          return null;
      }

      return $header.children('p').eq(0);
  };

  NestedList.prototype.createHeaderToggles = function() {
      if (!this.$header || !this.showHeaderToggles) {
          return null;
      }

      var $target = this.$header.children('span.nested-actions'),
          actionsStr = $target.length ? '' : '<span class="nested-actions">';
          actionsStr += '<i class="fa fa-plus-circle toggle" id="show-all"></i><i class="fa fa-minus-circle toggle" id="hide-all"></i>';
          actionsStr += $target.length ? '' : '</span>';

      if ($target.length) {
          $target.append(actionsStr);
      } else {
          this.$header.append(actionsStr);
      }
      return this.$header.find('#show-all, #hide-all');
  };

  NestedList.prototype.removeIdentifiers = function() {
      this.$node.removeAttr('data-load');
  };

  NestedList.prototype.getNodeFromTarget = function($target) {
      return $target.is('li') ? $target : $target.closest('li');
  };

  NestedList.prototype.insertNode = function(node) {
      var children = this.lookup[node.parent.id], childNode;

      if (!node.parent.$node.children('ul').length) {
          node.parent.$node.append('<ul></ul>');
      }

      node.parent.$node.children('ul').append(node.$node);
      node.inDOM = true;
  };

  NestedList.prototype.findNodesFromData = function(data) {
      return $(data).find('li');
  };

  function NestedTable($nested, options) {
      this.$nested = $nested;
      this.root = options.root;
      this.$content = this.$nested.find('tbody');
      this.nodes = {};
      this.$nodes = this.$content.children('tr');
      this.manualIds = true;
      this.lookup = this.root.buildLookup.call(this);
      this.showHeaderToggles = true;
      this.$header = this.getHeader();
      this.excludedClickedItems = ['.nested-disable-toggle', 'i', 'label', 'input', 'span', 'a'];
      this.nodeShowAll = options.nodeShowAll;

      if (options) {
          for (var option in options) {
              switch (option) {
                  case 'showHeaderToggles': {
                      this[option] = options[option];
                      break;
                  }
              }
          }
      }

      this.headerToggles = this.createHeaderToggles();
      this.showAll = this.root.showAll.bind(this);
      this.hideAll = this.root.hideAll.bind(this);

      if (this.headerToggles) {
          this.headerToggles.filter('#show-all').on('click', this.showAll);
          this.headerToggles.filter('#hide-all').on('click', this.hideAll);
      }

      this.$nodes.filter('tr[data-level="1"]').each(function(i, node) {
          this.buildNode.call(this, $(node), null, true);
      }.bind(this));

      this.$content.on('click', 'tr', function(e) {
          $(this).data('instance').toggle(e);
      });

      this.$nested.addClass('nested-visible').removeClass('nested-loading');
  }

  // buildNode will create all the NestedNode instances
  // ~ $node - jquery Object (required) of an element you want to build
  // ~ parent - object (optional) the parent instance, must be valid unless it's a top level node
  // ~ shouldInsert - boolean (optional, default: false) Will insert into DOM, required if using ajax
  NestedTable.prototype.buildNode = function($node, parent, inDOM) {
      if ($node.data('level') > 10) {
          return false;
      }

      var $children = this.lookup[$node.data('id')],
          node = new NestedNode($node, {
              parent: parent || null,
              $children: $children && $children.length && $node.data('level') != 10 ? $children : null,
              nested: this,
              inDOM: inDOM ? true : false
          });

      if (parent) {
          parent.children = parent.children || {};
          parent.children[node.id] = node;
      }

      // if children exist loop over them, pass it's node element and parent instance
      if ($children && $children.length) {
          $.each($children, function(i, child) {
              this.buildNode($(child), node, inDOM);
          }.bind(this));
      }
  };

  NestedTable.prototype.getHeader = function() {
      var $header = this.$nested.find('thead');

      if (!$header.length) {
          return null;
      }

      return $header.children('tr').eq(0);
  };

  NestedTable.prototype.createHeaderToggles = function() {
      if (!this.$header) {
          return null;
      }

      var $target = this.$header.children('th.nested-actions');

      if (!this.showHeaderToggles) {
          if (!$target.length) {
              $('<th/>').appendTo(this.$header);
          }
          return null;
      }

      var showAllText = this.$nested.data('show-all-text'),
          hideAllText = this.$nested.data('hide-all-text'),
          actionsStr = $target.length ? '' : '<th class="nested-actions">';
          actionsStr += '<i class="fa fa-plus-circle toggle" id="show-all"></i><i class="fa fa-minus-circle toggle" id="hide-all"></i>';
          actionsStr += $target.length ? '' : '</th>';

      if ($target.length) {
          $target.append(actionsStr);
      } else {
          this.$header.append(actionsStr);
      }
      var $icons = this.$header.find('#show-all, #hide-all');

      if (showAllText) {
        $icons.filter('#show-all').tooltipster({
          content: showAllText
        });
      }

      if (hideAllText) {
        $icons.filter('#hide-all').tooltipster({
          content: hideAllText
        });
      }

      return $icons;
  };

  NestedTable.prototype.createToggle = function(node) {
      var $actions = node.$node.children('td.nested-actions'),
          toggleText = this.$nested.data('toggle-text');

      if (node.$node.data('result')) {
        node.$toggle = null;
        return;
      }

      if (!node.$children && !node.isLoad) {
          if (!$actions.length) {
              $('<td/>').appendTo(node.$node);
          }
          node.$toggle = null;
          return;
      }

      if (!$actions.length) {
          $actions = $('<td/>').addClass('nested-actions').appendTo(node.$node);
      }

      $toggle = node.isVisible ?
          '<i class="fa fa-plus-circle nested-toggle"></i>':
          '<i class="fa fa-minus-circle nested-toggle"></i>';

      $actions.append($toggle);

      if (toggleText) {
        $actions.find('i.nested-toggle').tooltipster({
          content: toggleText
        });
      }

      node.$toggle = $actions.find('i.nested-toggle');
      return node.$toggle;
  };

  NestedTable.prototype.removeIdentifiers = function() {
      this.$node.removeAttr('data-parent data-id data-load');
  };

  NestedTable.prototype.getNodeFromTarget = function($target) {
      return $target.is('tr') ? $target : $target.closest('tr');
  };

  NestedTable.prototype.insertNode = function(node) {
      var children = this.lookup[node.parent.id], childNode, pathInserted;

      if (children) {
          var revChildren = children.slice().reverse();
          $.each(revChildren, function(i, child) {
              childNode = this.nodes[child.data('id')];
              pathInserted = false;

              if (!childNode || !childNode.inDOM || node.id === childNode.id) {
                  return true;
              }

              $.each($.map(childNode.getPathDown(childNode), function(value, index) {
                  return [value];
              }).reverse(), function(j, pathNode) {
                  if (pathNode.inDOM) {
                      pathInserted = true;
                      node.$node.insertAfter(pathNode.$node);
                      node.inDOM = true;
                      return false;
                  }
              });

              if (pathInserted) {
                  return false;
              }

              node.$node.insertAfter(childNode.$node);
              node.inDOM = true;
              return false;
          }.bind(this));

          if (node.inDOM) {
              return;
          }
      }

      node.inDOM = true;
      node.$node.insertAfter(node.parent.$node);
  };

  NestedTable.prototype.findNodesFromData = function(data) {
      return $(data).find('tr');
  };

  function NestedNode($node, options) {
      var self = this;
      this.inDOM = options.inDOM;
      this.$node = $node;
      this.id = this.$node.data('id');
      this.level = this.$node.data('level') || options.level;
      this.isLast = this.level === 10 ? true : false;
      this.nested = options.nested;

      // assign node instance to nodes obj
      this.nested.nodes[this.id] = this;

      this.$nested = this.nested.$nested;
      this.data = options.data;
      this.isLoad = !options.$children && this.$node.data('load') ? true : false;
      this.isDialogTrigger = this.$node.data('trigger') ? true : false;
      this.parent = options.parent;
      this.$children = options.$children;
      this.addShowAllAction = this.nested.nodeShowAll === true ||
                              $.isArray(this.nested.nodeShowAll) &&
                              this.nested.nodeShowAll.indexOf(this.level) > -1 ? true : false;
      this.$toggle = false;
      this.$showAll = false;
      this.childrenVisible = false;
      this.isVisible = this.setVisibility();

      this.nested.removeIdentifiers.call(this);
      this.$node.data('instance', this);

      if (this.addShowAllAction) {
          this.$showAll = this.createShowAllAction();
      }

      if (this.isDialogTrigger) {
          this.$node.on('click', function(e) {
              this.triggerDialog(e);
          }.bind(this));
      }

      if (this.isLoad) {
          this.$node.on('click', function(e) {
              this.load(e);
          }.bind(this));
      }

      this.$node.find('.tooltip').each(function() {
        tippy($(this)[0], {
          animation: 'shift-away',
          animateFill: false
        });
      });

      if (!this.$children && !this.isLoad || this.isLast) {
          this.isLast = true;
          this.$node.addClass('nested-last');
          if (options.addPipes) {
            this.createPipes();
          }
          return;
      }

      if (options.addPipes) {
          this.createPipes();
      }
      this.$node.addClass('toggled');
  }

  NestedNode.prototype.getSiblings = function() {
    if (this.parent) {
        var $siblings = this.nested.lookup[this.parent.id];

        return $.grep($siblings, function($sibling) {
            return !$sibling.is(this.$node);
        });
    }

    return false;
  };

  NestedNode.prototype.hideActions = function() {
    if (this.$toggle) {
      this.$toggle.addClass('hide');
    }

    if (this.$showAll) {
      this.$showAll.addClass('hide');
    }
  };

  NestedNode.prototype.showActions = function() {
    if (this.$toggle) {
      this.$toggle.removeClass('hide');
    }

    if (this.$showAll) {
      this.$showAll.removeClass('hide');
    }
  };

  NestedNode.prototype.createPipes = function() {
      for (var i = 0; i < this.level - 1; i++) {
          if (this.isLast && i === 0) {
              this.$node.children().eq(0).prepend('<div class="pipe"></div><div class="pipe pipe-last"></div>');
          } else {
              this.$node.children().eq(0).prepend('<div class="pipe"></div>');
          }
      }
  };

  NestedNode.prototype.createShowAllAction = function() {
      // if $toggle exists or is not inserted yet (null is when doesn't exist)
      if (this.$toggle || this.$toggle === false) {
        var showAllText = this.$nested.data('show-all-text'),
            $showAll = $('<i/>').addClass('fa fa-eye nested-show-all').on('click', this.show.bind(this, null, true));

        $showAll.insertBefore(this.$toggle ? this.$toggle : this.nested.createToggle(this));

        if (showAllText) {
          $showAll.tooltipster({
            content: showAllText
          });
        }

        return $showAll;
      }

      return null;
  };

  NestedNode.prototype.loadSuccess = function(data, showAll) {
      if (!this.isLoad || !data) {
          return true;
      }

      var $data = this.nested.findNodesFromData(data);
      this.nested.root.buildLookup.call(this.nested, this, $data);
      var $children = this.nested.lookup[this.id];

      this.$node.removeClass('nested-loading');

      // loop over top level data elements and build all data state
      $.each($children, function(i, $childNode) {
          $childNode.removeClass('hidden').addClass('visible');
          this.nested.buildNode($childNode, this, false);
      }.bind(this));

      this.$children = $children;

      // if filter active run filter on nested again
      if (this.nested.root.filterActive) {
          this.nested.root.filterActive = false;
          this.nested.root.filter(this.nested.root.filterMatch);
      }

      this.isLoad = false;

      if (showAll) {
          this.show(null, true);
      }
  };

  NestedNode.prototype.load = function(event, showAll) {
      if (this.matchesExcludedItems(event) || !this.isLoad || this.nested.root.filterActive && !this.isFiltered) {
          return true;
      }

      if (showAll !== true) {
          showAll = false;
      }

      this.$node.addClass('nested-loading');

      var callback = function(data) {
          this.loadSuccess(data, showAll);
      }.bind(this);

      if (typeof this.data === 'function') {
          this.data(callback);
      } else {
          this.defaultLoader(callback);
      }
  };

  NestedNode.prototype.defaultLoader = function(callback) {
      var url = this.$node.data('load');

      if (typeof url !== 'string') {
          return null;
      }

      var data = $.ajax({
          url: contextPath + url,
          async: true,
          dataType: 'html',
          data: {level: this.level + 1, parent: "" + this.id},
          success: callback
      });
  };

  NestedNode.prototype.triggerDialog = function(event) {
      if (this.matchesExcludedItems(event) || this.isLoad || this.nested.root.filterActive && !this.isFiltered) {
          return true;
      }

      var options = this.$node.data('trigger-options') ? JSON.parse(this.$node.data('trigger-options').replace(/'/g, '"')) : {};
      dialog.init(null, this.$node.data('trigger'), this.$node.data('trigger-title'), options);
  };

  NestedNode.prototype.getPathUp = function() {
      var nodes = [this];

      if (this.parent) {
          return nodes.concat(this.parent.getPathUp.call(this.parent));
      }

      return nodes;
  };

  NestedNode.prototype.getPathDown = function(node, prevChildren) {
      var self = this,
          children = {};

      if (!node.$children || prevChildren && !prevChildren.hasOwnProperty(node.id)) {
          children[node.id] = node;
      }

      if (node.$children) {
          $.each(node.$children, function() {
              children = $.extend({}, children, self.getPathDown.call(self, $(this).data('instance'), children));
          });
      }

      return children;
  };

  NestedNode.prototype.setVisibility = function() {
      var isVisible = false;

      if (this.$node.hasClass('visible') || !this.parent) {
          isVisible = true;
      }

      if (isVisible) {
          this.show();
      } else {
          this.hide();
      }
      return isVisible;
  };

  NestedNode.prototype.shouldPreventToggle = function(e) {
      if (typeof e === 'undefined' || e === null) {
          return false;
      }

      var $target = $(e.target),
          $node = this.nested.getNodeFromTarget.call(this, $target),
          continueToggle = true;

      e.stopPropagation();

      // if event target node id isn't equal to triggered node id
      if ($node.data('instance').id !== this.parent.id) {
          continueToggle = false;
      }

      if (continueToggle) {
          // if any of the excluded clicked items match the event target disallow toggle from proceeding
          continueToggle = !this.matchesExcludedItems(e);
      }

      if (!continueToggle) {
          return true;
      }
      return false;
  };

  NestedNode.prototype.matchesExcludedItems = function(e) {
      if (typeof e === 'undefined' || e === null) {
          return false;
      }

      var $target = $(e.target),
          matches = false;

      $.each(this.nested.excludedClickedItems, function() {
          if (!matches) {
              switch(this.slice(0, 1)) {
                  case '.': {
                      if ($target.hasClass(this.slice(1))) {
                          matches = true;
                      }
                      break;
                  }
                  case '#': {
                      if ($target.attr('id') === this.slice(1)) {
                          matches = true;
                      }
                      break;
                  }
                  default: {
                      if ($target.prop('tagName') === this.toUpperCase()) {
                          if ($target.hasClass('nested-toggle')) {
                              return false;
                          }
                          matches = true;
                      }
                      break;
                  }
              }
          }
          return !matches;
      });

      return matches;
  };

  NestedNode.prototype.toggle = function(e) {
      if (this.isLoad || this.isLast) {
          return true;
      }

      if (this.childrenVisible) {
        $.each(this.children, function(i, childNode) {
          childNode.hide(e, true);
        });
        return true;
      }

      $.each(this.children, function(i, childNode) {
          childNode.show(e);
      });
  };

  NestedNode.prototype.show = function(event, recursive) {
    if (this.shouldPreventToggle(event) || (this.nested.root.filterActive && !this.isFiltered)) {
      return true;
    }

    var lastClass = this.isLast ? ' nested-last' : '';

    if (!this.inDOM && !this.nested.root.filterActive ||
        !this.inDOM && this.nested.root.filterActive && this.isFiltered) {
      this.nested.insertNode(this);
    }

    this.isVisible = true;
    this.$node[0].className = 'visible' + lastClass;
    this.setVisibleToggle();

    // if children exist and children are visible
    if (this.parent && this.parent.$node.length) {
      this.parent.childrenVisible = true;
    }

    if (recursive) {
      if (this.isLoad) {
        this.load(null, true);
      } else if (!$.isEmptyObject(this.children)) {
        $.each(this.children, function(i, childNode) {
          childNode.show(null, true);
        });
      }
    }
  };

  NestedNode.prototype.hide = function(event, recursive, force) {
    if (!force) {
      if (this.shouldPreventToggle(event) ||
          (!this.parent || !this.parent.$node.length) ||
          (this.nested.root.filterActive && !this.isFiltered)) {
          return true;
      }
    }

    if (recursive && !$.isEmptyObject(this.children)) {
      $.each(this.children, function(i, childNode) {
        childNode.hide(null, recursive);
      });
    }

    var lastClass = this.isLast ? ' nested-last' : '';

    this.isVisible = false;
    this.$node[0].className = 'hidden' + lastClass;
    this.setHiddenToggle();

    // if filtered node must be force hidden (no longer toggleable)
    if (this.parent) {

      // If instance has been set (it wont have on initial run)
      if (this.$node.data('instance')) {
        var childrenVisible = false,
            siblingInstance;

        $.each(this.getSiblings(), function(i, siblingNode) {
          siblingInstance = siblingNode.data('instance');
          if (siblingInstance.isVisible) {
            childrenVisible = true;
            return false;
          }
        }.bind(this));

        if (!childrenVisible) {
          this.parent.childrenVisible = false;
        }
      }
    }
  };

  NestedNode.prototype.setHiddenToggle = function() {
    var toggleParentIcon = true;

    if (this.nested.root.filterActive) {
      var siblingInstance;
      $.each(this.getSiblings(), function(i, siblingNode) {
        siblingInstance = siblingNode.data('instance');
        if (siblingInstance.isFiltered && siblingInstance.isVisible) {
          toggleParentIcon = false;
          return false;
        }
      });
    }

    if (toggleParentIcon && this.parent && this.parent.$toggle) {
        this.parent.$toggle.removeClass('fa-minus-circle').addClass('fa-plus-circle');
    }

    // initial run toggle doesn't exist so create it
    if (this.$toggle === false) {
        this.nested.createToggle(this);
        return;
    }

    // toggle shouldn't exist so break
    if (this.$toggle === null) return false;
    this.$toggle.removeClass('fa-plus-circle').addClass('fa-minus-circle');
  };

  NestedNode.prototype.setVisibleToggle = function() {
      if (this.parent && this.parent.$toggle) {
          this.parent.$toggle.removeClass('fa-plus-circle').addClass('fa-minus-circle');
      }

      // initial run toggle doesn't exist so create it
      if (this.$toggle === false) {
          this.nested.createToggle(this);
          return;
      }

      // toggle shouldn't exist so break
      if (this.$toggle === null) return false;
      this.$toggle.removeClass('fa-minus-circle').addClass('fa-plus-circle');
  };

  NestedNode.prototype.addError = function() {
      this.nested.root.filter(this.$node);
  };


})(jQuery, arc);
