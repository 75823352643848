(function($) {

  function Input($input, options) {
    this.$content = $('#content');
    // cache the #content div spacing to alleviate issue where dropdown overflows scrollable region.
    this.contentPrevSpacing = parseInt(this.$content.css('padding-bottom'));
    this.$document = $(document);
    this.$window = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$input = $input;
    this.options = options;
    // previous value
    this.prevValue = '';
    // initial input value
    this.value = this.$input.val();
    // callbacks and default keyup/click event calls
    this.onChangeFuncs = [this.updateValue, this.emptyCallback, this.onTypeCallback];
    this.onInit = typeof this.options.onInit === 'function' ? this.options.onInit : null;
    this.onEmpty = typeof this.options.onEmpty === 'function' ? this.options.onEmpty : null;
    this.onType = typeof this.options.onType === 'function' ? this.options.onType : null;
    this.onEnter = typeof this.options.onEnter === 'function' ? this.options.onEnter : null;
    this.onChange = typeof this.options.onChange === 'function' ? this.options.onChange : null;
    // wrap input in a div given relative styling to support various functionality
    this.$wrapper = this.createWrapper();
    this.$actions = null;
    // ability to clear input when characters entered
    this.clear = this.options.clear || null;
    this.$clear = null;
    // ability to add searching spinner, and externally call .searchComplete() callback to remove spinner
    this.search = this.options.search || null;
    this.$search = null;
    this.$searching = null;
    this.searchActive = null;
    // only show search when character limit has been hit
    this.searchCharacterLimit = typeof this.options.searchCharacterLimit === 'number' ? this.options.searchCharacterLimit : 1;
    // timeout to remove the search polling spinner in case the callback never returns the function call.
    this.searchTimeout = null;
    this.searchTimeoutDelay = this.options.searchTimeoutDelay || 15000;
    // ability to display dropdown (overridden as true if suggestions are given)
    this.dropdown = typeof this.options.dropdown === 'object' || typeof this.options.dropdown === 'function' ? this.options.dropdown : null;
    this.$dropdown = null;
    // focus dropdown items with key arrows down/up
    this.$highlightedDropdownItem = null;
    // dropdown states
    this.dropdownOpen = false;
    this.dropdownInDOM = false;
    this.suggestions = null;
    this.suggestionsCharacterLimit = typeof this.options.suggestionsCharacterLimit === 'number' ? this.options.suggestionsCharacterLimit : 3;
    // optionally turn off default filtering of suggestions (front end fuzzy searching - doesn't include highlighting)
    this.suggestionsFiltering = typeof this.options.suggestionsFiltering === 'boolean' ? this.options.suggestionsFiltering : true;
    // to prevent duplicate requests on suggestions ajax (can't use prevValue as that isn't updated on change only keypresses)
    this.suggestionValue = this.value;
    // configure suggestions delay of requests (debouncing) default is 1000, unused if suggestions isn't a string (ajaxable)
    this.suggestionsRequestDelay = typeof this.options.suggestionsRequestDelay === 'number' ? this.options.suggestionsRequestDelay : 1000;
    // do not change: used as a timeout for the debouncing mechanism
    this.suggestionsDebouncingTimeout = null;
    // ie8 compatibility input versions, truthy value to turn on, only in IE8
    this.ie8Checkbox = this.options.ie8Checkbox || null;
    this.ie8Radio = this.options.ie8Radio || null;

    if (typeof this.options.suggestions === 'string' || $.isArray(this.options.suggestions)) {
      this.suggestions = this.options.suggestions;
    }

    if (this.search) {
      this.createSearch();
      this.onChangeFuncs.push(this.toggleSearch.bind(this));
    }

    if (this.clear) {
      this.createClear();
      this.onChangeFuncs.push(this.toggleClear.bind(this));
    }

    // when no dropdown data given but suggestions exist, override null value setting dropdown to true
    if (this.suggestions && !this.dropdown) {
      this.dropdown = true;
    }

    if (this.dropdown) {
      this.createDropdown();
    }

    if (this.suggestions) {
      this.createSuggestions();
    }

    // ie8 support
    if ($.browser.msie && $.browser.versionNumber === 8) {
      if (this.ie8Checkbox) {
        this.createIe8Checkbox();
      }

      if (this.ie8Radio) {
        this.createIe8Radio();
      }
    }

    if (this.onChange) {
      this.$input.on('change', function(event) {
        this.onChange();
      }.bind(this));
    }

    this.$input.on('keydown', function(event) {
      this.updatePrevValue();
    }.bind(this));

    this.$input.on('input', function(event) {
      this.change(event);
    }.bind(this));

    this.$input.on('keyup focus', function(event) {
      var code = (window.event) ? event.keyCode : event.which;
      if (code === 37 || code === 38 || code === 39 || code === 40) {
        return true;
      }

      // if user hit enter key, we assume they are searching or submitting something,
      // attempt to add searching capability if search option has been set
      if (code === 13) {
        this.searching();

        // onEnter callback
        if (this.onEnter && typeof this.onEnter === 'function') {
          this.onEnter(event);
        }
        return;
      }

      // run all change functions (functions defined in onChangeFuncs array)
      this.change(event);
    }.bind(this));

    if (this.onInit) {
      setTimeout(function() {
        this.onInit();
      }.bind(this), 0);
    }

    this.$input.data('instance', this);
  }

  Input.prototype.change = function(e) {
    var self = this;

    // for each func in onChangeFuncs array, run it.
    $.each(this.onChangeFuncs, function() {
      if (typeof this === 'function') {
        this.call(self, e);
      }
    });
  };

  // if user attempts to enter data or select a suggestion, we assume they are searching
  // or submitting a value
  Input.prototype.searching = function() {
    if (!this.search || this.searchActive || this.value.length < this.searchCharacterLimit) return;

    this.searchActive = true;
    this.closeDropdown();

    // $search initially not set, first time run add search spinner element to DOM
    if (!this.$searching) {
      this.$searching = $('<span/>').addClass('input-action-searching').appendTo(this.$actions);
    }

    this.fadeOutActions(function() {
      // show the $search spinner (this must be removed by external call to searchComplete())
      this.fadeInAction(this.$searching, function() {
        if (typeof this.search === 'function') {
          this.search(this.value, this.searchComplete.bind(this));
        }
      }.bind(this));
    }.bind(this));

    this.searchTimeout = setTimeout(function() {
      this.searchComplete();
    }.bind(this), this.searchTimeoutDelay);
  };

  Input.prototype.searchComplete = function() {
    if (!this.search || this.search && !this.$searching) return;

    this.searchActive = false;
    this.fadeOutAction(this.$searching, this.fadeInActions);

    clearTimeout(this.searchTimeout);
  };

  Input.prototype.createWrapper = function() {
    this.$input.appendTo($('<div/>').addClass('input-wrapper').prependTo(this.$input.parent()));
    return this.$input.parent();
  };

  Input.prototype.updatePrevValue = function() {
    this.prevValue = this.$input.val();
  };

  Input.prototype.updateValue = function() {
    this.value = this.$input.val();
  };

  Input.prototype.emptyCallback = function() {
    if (this.onEmpty && !this.value && typeof this.onEmpty === 'function') {
      this.onEmpty.call(this);
    }
  };

  // called when the user types / removes anything in the input
  Input.prototype.onTypeCallback = function() {
    if (this.onType && typeof this.onType === 'function') {
      this.onType.call(this);
    }
  };

  Input.prototype.createDropdown = function() {
    var $items = this.getDropdownItems();
    this.$dropdown = $('<dl></dl>').addClass('input-dropdown').append($items);
    this.onChangeFuncs.push(this.toggleDropdown.bind(this));

    this.$document.on('click.arc-input-dropdown', function(event) {
      // if input is clicked, don't destroy
      if ($(event.target).is(this.$input)) {
        return;
      }

      this.destroyDropdown();
    }.bind(this));

    // dropdown is aligned with input via relative parent
    this.$input.parent().css('position', 'relative');
  };

  // utility to test if dropdown is empty or not
  Input.prototype.dropdownHasContent = function() {
    return this.$dropdown && this.$dropdown.children('dd').length;
  };

  // stateful function to set the dropdown visibility at any given point
  Input.prototype.toggleDropdown = function() {
    if (!this.dropdownHasContent() || this.value && this.dropdownInDOM && this.dropdownOpen || !this.value && !this.dropdownInDOM) {
      return;
    }

    if (this.dropdownInDOM && !this.dropdownOpen) {
      this.openDropdown();
      return;
    }

    if (!this.dropdownInDOM) {
      this.addDropdown();
      return;
    }

    if (!this.value && this.dropdownInDOM) {
      this.destroyDropdown();
      return;
    }

    this.closeDropdown();
  };

  // returns all dropown items from defined options
  Input.prototype.getDropdownItems = function() {
    var $items = $(),
        dropdown = this.dropdown,
        addItemsFromObj = function(obj) {
          var $item;

          if (typeof obj.title === 'string') {
            $items = $items.add('<dt><span>' + obj.title + '</span></dt>');
          }

          if ($.isArray(obj.data)) {
            $.each(obj.data, function() {
              $item = $('<dd/>').attr('tabindex', '1').append((this.link ? $('<a/>').attr('href', this.link).text(this.text) : $('<span/>').text(this.text)));
              if (this.callback && typeof this.callback === 'function') {
                $item.data('callback', this.callback);
              }
              $items = $items.add($item);
            });
          }
        };

    if (typeof dropdown === 'function') {
      dropdown = dropdown();
    }

    if (typeof dropdown === 'object') {
      addItemsFromObj(dropdown);
    }

    if ($.isArray(dropdown)) {
      $.each(dropdown, function(i, obj) {
        if (typeof obj === 'object') {
          addItemsFromObj(obj);
        }
      });
    }

    return $items;
  };

  Input.prototype.closeDropdown = function() {
    this.dropdownOpen = false;
    // if highlight of dropdown items has occured, clear the fixed body state
    if (this.$highlightedDropdownItem || this.$highlightedDropdownItem === false) {
      this.$html.add(this.$body).css('overflow-y', '');
    }

    if ($.browser.msie && $.browser.versionNumber === 8) {
      this.$html.add(this.$body).removeAttr('style');
    }

    this.$highlightedDropdownItem = null;
    this.$window.off('resize.arc-input-dropdown');
    this.$input.add(this.$dropdown).removeClass('input-dropdown-open');
    this.$document.off('keyup.arc-input-dropdown-keys');
    this.$content.css('padding-bottom', this.contentPrevSpacing);

    if (this.suggestions) {
      this.clearSuggestions();
    }
  };

  Input.prototype.destroyDropdown = function(event) {
    this.dropdownInDOM = false;
    this.closeDropdown();
    this.$input.next('.input-dropdown').remove();
  };

  Input.prototype.openDropdown = function() {
    this.dropdownOpen = true;

    if (!$.browser.msie || $.browser.msie && $.browser.versionNumber > 9) {
      this.$window.on('resize.arc-input-dropdown', this.calcDropdown.bind(this));
    }

    this.$input.add(this.$dropdown.css('visibility', 'hidden')).addClass('input-dropdown-open');

    this.$document.on('keydown.arc-input-dropdown-keys', function(event) {
      this.keyDropdownScroll(event);
    }.bind(this));

    this.$document.on('keyup.arc-input-dropdown-keys', function(event) {
      this.keyDropdown(event);
    }.bind(this));

    // delay calcdropdown until dropdown height is fully rendered
    setTimeout(function() {
      this.calcDropdown();
      this.$dropdown.css('visibility', 'visible');
    }.bind(this), 10);
  };

  Input.prototype.addDropdown = function(cb) {
    this.dropdownInDOM = true;
    this.openDropdown();

    setTimeout(function() {
      this.$dropdown.insertAfter(this.$input);
    }.bind(this), 0);

    if (typeof cb === 'function') {
      cb(this.$dropdown);
    }
  };

  Input.prototype.keyDropdown = function(event) {
    var code = (window.event) ? event.keyCode : event.which;

    if (code === 13) {
      this.highlightedItemSelectedDropdown();
      return;
    }

    if (code === 40) {
      this.nextItemDropdown();
      return;
    }

    if (code === 38) {
      this.previousItemDropdown();
    }
  };

  Input.prototype.keyDropdownScroll = function(event) {
    var code = (window.event) ? event.keyCode : event.which;
    if (code === 13 || code === 40 || code === 38) {
      event.preventDefault();
      event.returnValue = false;
    }
  };

  Input.prototype.highlightedItemSelectedDropdown = function() {
    var $selected = this.$dropdown.children('dd').filter(':focus'),
        selectedText = $selected.data('text'),
        $selectedAnchor = $selected.children('a');

    if ($selectedAnchor.length && $selectedAnchor.attr('href')) {
      window.location.href = $selectedAnchor.attr('href');
      return;
    }

    if ($selected.data('callback')) {
      var dropdownItemCallback = $selected.data('callback');
      if (typeof dropdownItemCallback === 'function') {
        dropdownItemCallback();
      }
    }

    if (selectedText) {
      this.set(selectedText);
      this.searching();
    }
  };

  Input.prototype.nextItemDropdown = function() {
    if (this.$input.is(':focus')) {
      this.$input.blur();
    }

    if (!this.$highlightedDropdownItem) {
      this.$highlightedDropdownItem = this.$dropdown.find('dd').eq(0);
    } else {
      this.$highlightedDropdownItem = this.$highlightedDropdownItem.nextAll('dd').eq(0);

      if (!this.$highlightedDropdownItem.length) {
        this.$highlightedDropdownItem = this.$dropdown.find('dd').eq(0);
      }
    }

    this.$highlightedDropdownItem.focus();
  };

  Input.prototype.previousItemDropdown = function() {
    if (this.$input.is(':focus')) {
      this.$input.blur();
    }

    if (!this.$highlightedDropdownItem) {
      this.$highlightedDropdownItem = this.$dropdown.find('dd').last();
    } else {
      this.$highlightedDropdownItem = this.$highlightedDropdownItem.prevAll('dd').eq(0);

      if (!this.$highlightedDropdownItem.length) {
        this.$highlightedDropdownItem = this.$dropdown.find('dd').last();
      }
    }

    this.$highlightedDropdownItem.focus();
  };

  // set maxHeight on dropdown based on available window height, also conditionally
  // assign padding-bottom to the #content wrapper to prevent overflowing non-scrollable regions
  Input.prototype.calcDropdown = function() {
    var height = ((this.$input.offset().top + this.$input.outerHeight()) - this.$content.offset().top) + this.$dropdown.outerHeight() + 50;

    // set maxHeight on dropdownOpen
    // var maxHeight = this.$window.height() - (this.$input.offset().top + this.$input.outerHeight() + this.$dropdown.outerHeight() + 50);
    // this.$dropdown.css('max-height', maxHeight);
    // this.$content.css('padding-bottom', this.contentPrevSpacing);

    // if ((this.$window.height() === this.$document.height() ||
    //     // IE only test as document renders extra 4px for native browser borders
    //     $.browser.msie && $.browser.versionNumber <= 9 &&
    //     this.$window.height() === (this.$document.height() - 4)) &&
    //     this.contentPrevSpacing < maxHeight) {
    //   this.$content.css('padding-bottom', maxHeight);
    // }

    if (height > this.$content.height()) {
      this.$content.css('padding-bottom', height - this.$content.height());
    }
  };

  Input.prototype.createSuggestions = function() {
    var self = this,
        // function requests then updates suggestions if more than 4 chars entered,
        // otherwise it clears suggestions, and closes the dropdown if no more content is given.
        setSuggestions = function() {
          // if value hasn't changed don't do anything
          if (this.dropdownOpen && this.value === this.prevValue) {
            return;
          }

          if (this.value.length >= this.suggestionsCharacterLimit) {
            this.requestSuggestions(this.updateSuggestions.bind(this));
            return;
          }

          this.clearSuggestions();

          if (!this.dropdownHasContent()) {
            this.closeDropdown();
          }
        }.bind(this);

    // a suggestion was clicked, enter searched state if valid, set new value to input
    this.$input.parent().on('click', 'dd.suggestion', function(event) {
      var text = $(this).data('text');
      self.set(text);
      self.searching();
    });

    this.onChangeFuncs.push(setSuggestions.bind(this));
  };

  Input.prototype.requestSuggestions = function(cb) {
    var self = this,
        cbIsFunc = typeof cb === 'function',
        filterData,
        suggestionAjaxRequest = function() {
          $.ajax({
            url: this.suggestions,
            data: {'query': this.value},
            success: function(data) {
              if (cbIsFunc) cb.call(this, filterData(data));
            }.bind(this),
            error: function(xhr) {
              if (xhr.status === 401) {
                window.location.reload();
              }
            }
          });
        }.bind(this),
        suggestionsDebounce = function() {
          this.suggestionsDebouncingTimeout = setTimeout(suggestionAjaxRequest.bind(this), this.suggestionsRequestDelay);
        }.bind(this);

    if (!this.suggestions) return;

    filterData = function(data) {
      return $.grep(data, function(el, index) {
        return index == $.inArray(el, data);
      });
    };

    // if suggestions is a url attempt to ajax it, also make sure the previous
    // suggestion value request made isn't the same to prevent duplicate requests.
    if (typeof this.suggestions === 'string') {
      if (this.suggestionsDebouncingTimeout) {
        clearTimeout(this.suggestionsDebouncingTimeout);
      }

      suggestionsDebounce();
      this.suggestionValue = this.value;
      return;
    }

    this.suggestionValue = this.value;

    if ($.isArray(this.suggestions) && cbIsFunc) {
      // clone existing data
      cb(filterData(this.suggestions.slice(0)));
    }
  };

  Input.prototype.clearSuggestions = function() {
    this.$highlightedDropdownItem = false;
    this.$dropdown.find('.suggestion').remove();
  };

  Input.prototype.updateSuggestions = function(data) {
    if (!this.value || !data) return;
    var self = this,
        $items = $(),
        results = this.findSuggestions(data),
        match;

    this.clearSuggestions();

    // if search is being performed abandon suggestion update
    if (this.search && this.searchActive) {
      return;
    }

    // suggestions data is hardcoded, not a url, reassign
    if ($.isArray(this.suggestions)) {
      this.suggestions = data;
    }

    // no results found and no content in dropdown, close it
    if (!results.length) {
      if (!this.dropdownHasContent()) {
        this.closeDropdown();
      }
      return;
    }

    // render suggestions into dropdown, with bolding of key words
    $.each(results, function(i, text) {
      match = new RegExp(this.value.toLowerCase().replace('-', '').split(' ').join('|'), 'gi');

      $items = $items.add($('<dd/>')
        .addClass('suggestion')
        .attr('tabindex', '1')
        .data('text', text)
        .html(text.replace(match, '<span class="input-highlight">$&</span>')));
    }.bind(this));

    this.$dropdown
      .append('<dt class="suggestion"><span> ' + arc.i18n.get('arc.search.input.suggestions.title') + ' </span></dt>', $items);

    // dropdown may be closed if no data given in this.dropdown, update state to open if this is the case.
    this.toggleDropdown();
    this.calcDropdown();
  };

  // filtering given suggestions to only return match against search phrase
  // pass filterSuggestions as false to disable this functionality
  // only matches whole world, mistaken phrases are currently dealt server side.
  Input.prototype.findSuggestions = function(data) {
    if (!this.value || !data) return;
    if (!this.suggestionsFiltering) {
      return data;
    }

    var items = [],
        itemData = data.slice(0),
        itemDataLen = itemData.length;

    for(var i = 0; i < itemDataLen; i += 1){
      if(this.scoreSuggestion(itemData[i].toLowerCase()) >= 0.2) {
        items.push(itemData[i]);
      }
    }

    return items;
  };

  Input.prototype.splitStringsForComparison = function(string) {
    var i, j, ref, s, v;
    s = string.toLowerCase();
    v = new Array(s.length - 1);
    for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
      v[i] = s.slice(i, i + 2);
    }
    return v;
  };

  Input.prototype.getMatchedSuggestionIndices = function(suggestion, phrasePairs, suggestionPairs) {
    var hit_count, j, k, len, len1, x, y, matches = [];
    if (this.value.length > 0 && suggestion.length > 0) {
      if (!phrasePairs && !suggestionPairs) {
        phrasePairs = this.splitStringsForComparison(this.value.toLowerCase());
        suggestionPairs = this.splitStringsForComparison(suggestion);
      }
      hit_count = 0;
      for (j = 0, len = phrasePairs.length; j < len; j++) {
        x = phrasePairs[j];
        for (k = 0, len1 = suggestionPairs.length; k < len1; k++) {
          y = suggestionPairs[k];
          if (x === y) {
            matches.push(x);
          }
        }
      }
    }

    return matches;
  };

  Input.prototype.scoreSuggestion = function(suggestion) {
    var count = 0, phrasePairs, suggestionPairs;

    if (this.value.length > 0 && suggestion.length > 0) {
      phrasePairs = this.splitStringsForComparison(this.value.toLowerCase());
      suggestionPairs = this.splitStringsForComparison(suggestion);
      count = this.getMatchedSuggestionIndices(suggestion, phrasePairs, suggestionPairs);
    }

    if (count.length > 0) {
      return (2.0 * count.length) / (phrasePairs.length + suggestionPairs.length);
    }

    return 0.0;
  };

  Input.prototype.createActions = function() {
    this.$actions = $('<span/>')
      .addClass('input-actions')
      .appendTo(this.$wrapper);
  };

  Input.prototype.fadeInAction = function($action, cb) {
    var self = this;
    if ($action.is(':visible')) return;
    $action
      .css({'display': 'inline-block', 'opacity': 0})
      .animate({
        'opacity': 1
      }, 200, function() {
        setTimeout(function() {
          if (typeof cb === 'function') {
            cb.call(self);
          }
        }, 0);
      });
  };

  Input.prototype.fadeOutAction = function($action, cb) {
    var self = this;
    if (!$action.is(':visible')) return;

    $action
      .animate({
        'opacity': 0
      }, 200, function() {
        $action.css('display', 'none');

        setTimeout(function() {
          if (typeof cb === 'function') {
            cb.call(self);
          }
        }, 200);
      });
  };

  Input.prototype.fadeOutActions = function(cb) {
    this.removeClear();
    this.removeSearch();

    setTimeout(function() {
      if (typeof cb === 'function') {
        cb();
      }
    }.bind(this), 300);
  };

  Input.prototype.fadeInActions = function(cb) {
    this.addClear();
    this.addSearch();

    setTimeout(function() {
      if (typeof cb === 'function') {
        cb();
      }
    }.bind(this), 200);
  };

  Input.prototype.createSearch = function() {
    if (!this.$actions) {
      this.createActions();
    }

    if ($.browser.msie && $.browser.versionNumber === 8) {
      this.$search = $('<img/>')
        .addClass('input-action-search')
        .attr('src', arc.contextPath + '/images/arc/input-search.png');
    } else {
      this.$search = $('<span/>').addClass('input-action-search');
    }

    this.$search
      .appendTo(this.$actions)
      .on('click', function() {
          this.searching();
          this.toggleClear();
        }.bind(this));

    this.toggleSearch();
  };

  Input.prototype.toggleSearch = function() {
    if (this.searchActive) return;

    if (this.value.length >= this.searchCharacterLimit) {
      this.addSearch();
      return;
    }

    this.removeSearch();
  };

  Input.prototype.removeSearch = function() {
    if (!this.search || this.search && !this.$search) return;
    this.fadeOutAction(this.$search);
  };

  Input.prototype.addSearch = function() {
    if (!this.search || this.search && !this.$search || this.searchActive) return;
    this.fadeInAction(this.$search);
  };

  Input.prototype.createClear = function() {
    if (!this.$actions) {
      this.createActions();
    }

    if ($.browser.msie && $.browser.versionNumber === 8) {
      this.$clear = $('<img/>')
        .addClass('input-action-clear')
        .attr('src', arc.contextPath + '/images/arc/input-clear.png');
    } else {
      this.$clear = $('<span/>').addClass('input-action-clear');
    }

    this.$clear
      .appendTo(this.$actions)
      .on('click', function() {
        this.empty();
        this.fadeOutActions();
      }.bind(this));

    this.toggleClear();
  };

  Input.prototype.toggleClear = function() {
    if (this.searchActive) return;

    if (this.value) {
      this.addClear();
      return;
    }

    this.removeClear();
  };

  Input.prototype.removeClear = function() {
    if (!this.clear || this.clear && !this.$clear) return;
    this.fadeOutAction(this.$clear);
  };

  Input.prototype.addClear = function() {
    if (!this.clear || this.clear && !this.$clear || this.searchActive) return;
    this.fadeInAction(this.$clear);
  };

  Input.prototype.empty = function() {
    this.$input.val('');
    this.value = '';
    this.prevValue = '';

    if (this.onEmpty && typeof this.onEmpty === 'function') {
      this.onEmpty.call(this);
    }
  };

  Input.prototype.set = function(value) {
    this.$input.val(value);
    this.value = value;
  };

  Input.prototype.createIe8Checkbox = function() {
    var $label = this.$wrapper.next('label').addClass('input-checkbox');

    if (this.$input.hasClass('checkbox-indent')) {
      $label.addClass('checkbox-indent');
    }

    if (this.$input.hasClass('checkbox-right')) {
      $label.addClass('checkbox-right');
    }

    this.updateIe8Disabled($label);
    this.$input.on('change', this.updateIe8Disabled.bind(this, $label));
    this.$input.on('click', this.toggleIe8Checked.bind(this));
  };

  Input.prototype.createIe8Radio = function() {
    var $label = this.$wrapper.next('label').addClass('input-radio');

    if (this.$input.hasClass('radio-indent')) {
      $label.addClass('radio-indent');
    }

    if (this.$input.hasClass('radio-right')) {
      $label.addClass('radio-right');
    }

    this.$input.on('change', this.updateIe8Disabled.bind(this, $label));
    this.$input.on('click', this.toggleIe8Radios.bind(this));
  };

  Input.prototype.toggleIe8Radios = function(e, simulated) {
    var $label = this.$wrapper.next('label'),
        $otherRadios = $('input[name="' + this.$input.attr('name') + '"]'),
        $prevSelectedRadio = $otherRadios.filter(':checked');

    $otherRadios.prop('checked', false).parent().next('label').removeClass('input-checked');
    this.$input.prop('checked', true);
    $label.addClass('input-checked');

    // force paint so styling reflects selection
    this.$input.hide().show();
    $prevSelectedRadio.hide().show();

    // callback and 'runCallback' param explicitly not declared as false boolean
    if (typeof this.options.onIe8RadioCheck === 'function' && simulated !== true) {
      this.options.onIe8RadioCheck.call(this);
    }
  };

  Input.prototype.updateIe8Disabled = function($label) {
    if (this.$input.is(':disabled')) {
      $label.addClass('input-disabled');
      return;
    }

    $label.removeClass('input-disabled');
  };

  Input.prototype.toggleIe8Checked = function() {
    var $label = this.$wrapper.next('label');

    // checkbox was previously selected already, remove selection
    if ($label.hasClass('input-checked')) {
      this.$input.prop('checked', false);
      $label.removeClass('input-checked');
      return;
    }

    // checkbox has just been checked, add state to label
    if (this.$input.is(':checked')) {
      $label.addClass('input-checked');
    }

    if (typeof this.options.onIe8CheckboxCheck === 'function') {
      this.options.onIe8CheckboxCheck.call(this);
    }
  };

  function input($input, options) {
    return new Input($input, options);
  }

  $.extend(true, arc, {
    input: input
  });

  $.fn.input = function(options) {
    return new Input($(this), options);
  };

})(jQuery);
