(function($, arc) {
  $(document).ready(function() {
    new Header();
  });

  function Header() {
    this.$window = $(window);
    this.$header = $('.header').eq(0);
    this.$subHeader = $('.sub-header').eq(0);
    this.$notice = this.$header.filter('.header-has-notice').children('div').eq(1);
    this.$vehicle = this.$header.filter('.header-has-vehicle').find('.trigger-vehicle-details').add(this.$subHeader.find('.trigger-vehicle-details'));
    this.$notice.on('click', this.notice.bind(this));
    this.$vehicle.on('click', this.vehicle.bind(this));
    this.$vehicleDetails = $("a.trigger-vehicle-details");
  }

  Header.prototype.notice = function() {
    var url = this.$notice.data('url');

    if (this.$window.width() > 1024) {
      if (url) {
        window.location.href = url;
      }

      return;
    }
    var dialogConfig = {
      bodySelector: $('#notice-content').clone().removeClass('hide')
    };

    if (url) {
      dialogConfig.buttonKeys = {
        'button.ok': {
          classes: 'button button-primary',
          callback: function() {
            this.close();
          }
        },
        'arc.notice.view': {
          classes: 'button button-secondary',
          callback: function() {
            window.location.href = url;
            this.close();
          }
        }
      };
    }

    $i.dialog(dialogConfig);
  };

  Header.prototype.vehicle = function() {
    var sota;
    var asBuilt;
    var bmis;
    var detailsUrl = $i.defaultContextPath + '/vehicle/details?time=' + new Date().getTime();
    var dialog = $i.dialog({
      titleKey: 'arc.vehicle.summary.dialog.title',
      url: detailsUrl + ' #vehicle-details-content',
      width: '80%',
      buttonKeys: {
        'button.cancel': {
          classes: 'button-secondary',
          callback: function () {
            this.close();
          }
        },
	      'button.print': {
		      classes: 'button-primary',
		      callback: function () {
			      window.open($i.defaultContextPath + "/vehicle/summary");
			      this.close();
		      }
	      }
      },
      load: function () {
        var self = this,
          tabs = new arc.tabs(this.box.find('ul.tabs'), {
            initialised: function(tabs) {

              var vehicleSoftwareTab = $.grep(tabs, function(x) { return x.$tab[0].id === "vehicle-software-tab"; });

              // if SOTA exists (some users may not have that tab rendered at all)
              if (vehicleSoftwareTab.length) {
                $(vehicleSoftwareTab[0].$tab).one("click", function() {
                  sota = arc.sota(vehicleSoftwareTab[0].target, self);
                });
              }

              // find using id to avoid situation when one of tabs dissapear
              var asBuiltTab = $.grep(tabs, function(x) { return x.$tab[0].id === "asBuilt-tab"; });

              if (asBuiltTab.length) {
                asBuilt = arc.asBuilt(asBuiltTab[0].target, self);
              }

              var bmisTab = $.grep(tabs, function(x) { return x.$tab[0].id === "bmis-tab"; });

              if (bmisTab.length) {
                bmis = arc.bmis(bmisTab[0].target, self);
              }

              self.resize.call(self);
            },
            callback: function() {
              self.resize.call(self);
            }
          });
      },
      close: function() {
        if (sota) sota.destroy();
        if (asBuilt) asBuilt.destroy();
        if (bmis) bmis.destroy();
        dialog.overlay.remove();
        dialog.$el.remove();
      }
    });
  };
})(jQuery, arc);
