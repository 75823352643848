(function ($, arc) {

    function SimpleVin(options) {
        var self = this;
        this.options = options || {};

        this.$group = options.$group;
        this.$fullVin = options.$fullVin;
        this.$differentVinLink = options.$differentVinLink;
        this.$taCardVin = options.$taCardVin;
        this.$groupItems = this.$group.children('input.input-vin');
        this.$all = this.$group.add(this.$fullVin).add(this.$groupItems);
        this.locked = false;

        this.$group
            .on('paste', 'input', function (event) {
                self.groupPaste.call(self, $(this), event);
            })
            .on('keyup', 'input', function (event) {
                self.groupEnter.call(self, $(this), event);
            });
        this.$all.on('keyup', function (event) {
            self.enter.call(self, $(this), event);
        });

        this.$differentVinLink.on('click', function () {
            $(this).hide();
            self.$all.removeAttr('disabled');
            self.$fullVin.val('');
            self.$groupItems.each(function () {
                $(this).val('');
            });
            var $filledCard = self.$taCardVin.find(".ta-top");
            $filledCard.addClass("empty-card").removeClass("ta-top");
            self.$taCardVin.find(".ta-bottom").remove();
        });
    }

    SimpleVin.prototype.enter = function ($item, e) {
        var code = e.which || e.keyCode;

        // if code undefined, paste in session, code is space, tab, shift or cmd don't continue
        if ($item.data('paste') == true || code === 32 || code === 16 || code === 9 || code === 91) {
            return false;
        }

        // set new value to vin
        this.update($item, code);
    };

    SimpleVin.prototype.groupEnter = function ($item, e) {
        var code = e.which || e.keyCode;
        if ($item.data('paste') == true) {
            return false;
        }

        // delete
        if (code === 8) {
            var $prevItem = $item.prev('input');
            if ($prevItem.length && $prevItem.val().length) {
                var tmpVal = $prevItem.val();
                $prevItem.focus();
                $prevItem.val('');
                $prevItem.val(tmpVal);
            }
            return;
        }

        if (code <= 90 && code >= 48 || code >= 96 && code <= 105) {
            this.nextInput($item, e);
        }
    };


    SimpleVin.prototype.nextInput = function ($item, e) {
        var $nextItem = $item.next('input');

        if ($item.val() && $nextItem.length) {
            $nextItem.focus();
        }
    };

    SimpleVin.prototype.groupPaste = function ($item, event) {
        var $firstItem = $item.parent().children().eq(0),
            $nextItem = $firstItem.is(':disabled') ? $item.parent().children().eq(9) : $firstItem,
            text = event.originalEvent.clipboardData ? event.originalEvent.clipboardData.getData('Text') : window.clipboardData.getData('Text'),
            i = 0;

        $item.data('paste', true);

        // send to back of stack so val can be cleared.
        setTimeout(function () {
            $item.val('');

            if (!this.pasteValidates(text)) {
                return false;
            }

            do {
                $nextItem.val(text.slice(i, i + 1)).focus();
                i++;
                $nextItem = $nextItem.next('input');
            } while ($nextItem.length && text.slice(i).length);

            this.update($item);
            $item.data('paste', '');
        }.bind(this), 0);
    };

    SimpleVin.prototype.pasteValidates = function (text) {
        if (!text.length || text.match(/[^\w]|_/g) !== null) {
            return false;
        }

        return true;
    };

    SimpleVin.prototype.update = function ($item, code) {
        this.removeErrors();

        if ($item.hasClass('input-vin-full') || $item.hasClass('input-vin-short')) {
            $item.siblings('.input-vin-group').children('input.input-vin').each(function (i, input) {
                $(input).val($item.val().slice(i, i + 1));
            });
        }

        if ($item.hasClass('input-vin')) {
            $item.parent().siblings('input.input-vin-full').val(
                $item.parent().children().map(function () {
                    var $currentInput = $(this);
                    return $currentInput.val().length > 0 ? $currentInput.val() : '';
                }).get().join('')
            );
        }

        if (this.$fullVin.val().length === 17) {
            this.$all.attr('disabled', 'disabled');
            this.locked = true;
            this.checkIfVehicleWithVinExists(this.$fullVin.val());
        }
    };

    SimpleVin.prototype.checkIfVehicleWithVinExists = function (vin) {
        var thiz = this;
        $.ajax({
            url: $i.defaultContextPath + "/technical/assistance/checkVin?vin=" + vin,
            dataType: "json",
            success: function (data) {
                if (data.correct) {
                    var $emptyCard = thiz.$taCardVin.find(".empty-card");
                    $emptyCard.removeClass("empty-card").addClass("ta-top");
                    $("<div class='ta-bottom'>SALFA123123123123</div>").insertAfter($emptyCard);
                    thiz.$differentVinLink.show();
                } else {
                    thiz.$all.removeAttr('disabled');
                    thiz.$group.add(thiz.$fullVin).addClass("input-error");
                    thiz.$groupItems.last().focus();
                }
                thiz.locked = false;
            },
            error: function () {
                thiz.locked = false;
            }
        });
    };

    SimpleVin.prototype.removeErrors = function () {
        this.$group.add(this.$fullVin).removeClass('input-error');
    };

    $.extend(arc, {
        simpleVin: SimpleVin
    });
})(jQuery, arc);
