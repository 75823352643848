(function ($, arc) {
  function ProgressBar(container, options) {
    this.$container = container;
    this.currentStep = options.currentStep;
    this.stepsCount = options.totalSteps;
    this.progressPercentage = null;
    this.animationSpeed = options.animationSpeed;
    this.progressBarColor = options.progressBarColor;

    this.run();
  }

  ProgressBar.prototype.run = function () {
    this.calcProgressPercentage();
    this.build();
  };

  ProgressBar.prototype.calcProgressPercentage = function () {
    this.progressPercentage = Math.min(99, ((this.currentStep / this.stepsCount) * 100)).toFixed() + "%";
  };

  ProgressBar.prototype.animate = function () {
    this.$progressBar.animate({width: this.progressPercentage}, this.animationSpeed);
  };

  ProgressBar.prototype.build = function () {
    this.$progressBar = $('<div></div>');
    this.$progressBar.css({
      height: '100%',
      backgroundColor: this.progressBarColor,
      width: '0%'
    });

    this.$container.append(this.$progressBar);

    this.animate();
  };

  $.fn.progressBar = function (options) {
    var progress = new ProgressBar($(this), options);
    return this;
  }

})(jQuery, arc);



