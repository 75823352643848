var collectionUtilities;
$(document).ready(function () {
  collectionUtilities = (function ($, arc) {
    return {
      /**
       * Parses map(as string) from backend into JS object. Removes brackets, slice by comma to get entries and slice by equals to get key and value.
       *
       * @param {string} mapAsString
       * @returns {Object}
       */
      parseMapToObject: function (mapAsString) {
        var object = {};
        mapAsString
          .trim()
          .slice(1, -1)
          .split(',')
          .forEach(function(v) {
            var val = v.trim().split('=');
            object[val[0]] = val[1];
          })
        return object;
      }
    };
  })(jQuery, arc);
});
