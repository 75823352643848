(function(arc, $) {

  $('.mechanical-key-help').on('click', function(e) {
    e.preventDefault();
    var dialog = $i.dialog({
      titleKey: 'arc.vehicle.mechanical.key.help.title',
      closeable: false,
      autoOpen: true,
      bodySelector: '.dialog-mechanical-key-help',
      width: '80%',
      buttonKeys: {
        'button.close': {
          callback: function() {
            this.destroy();
          }
        }
      }
    });
  });

})(arc, jQuery);
