(function($) {
  $(document).ready(function() {
    var $notifications = $('.notification'),
        $notificationsAnimatable = $notifications.filter('.notification-animate');

    $notificationsAnimatable.each(function(i, notificationAnimatable) {
      var windowWidth = $(window).width(),
          $notificationAnimatable = $(notificationAnimatable),
          notificationAnimatableWidth = $notificationAnimatable.outerWidth(),
          contentMargin = windowWidth > 767 ? 40 : 0,
          offsetType = $notificationAnimatable.css('position') === 'fixed' ? 'left' : 'margin-left',
          $notificationIcon = $notificationAnimatable.find('.notification-icon').addClass('notification-icon-animate');

      switch (offsetType) {
        case 'left': {
          $notificationAnimatable.css('left', '-' + notificationAnimatableWidth);
          $notificationAnimatable.animate({'left': 0}, 600, notificationAnimateComplete);
          break;
        }
        case 'margin-left': {
          $notificationAnimatable.css({
            'margin-left': parseInt('-' + (notificationAnimatableWidth + contentMargin)),
            visibility: 'visible'
          });
          $notificationAnimatable.animate({'margin-left': '-' + contentMargin}, 600, notificationAnimateComplete);
          break;
        }
      }

      function notificationAnimateComplete() {
        $notificationAnimatable.addClass('notification-animate-highlight');
        setTimeout(function() {
          $notificationIcon.addClass('notification-icon-animate-scale-active');
          $notificationAnimatable
            .removeClass('notification-animate-highlight notification-animate')
            .css({
              'margin-left': '',
              'left': '',
              'visibility': ''
            });
        }, 1000);

        setTimeout(function() {
          $notificationIcon.addClass('notification-icon-animate-shake-active');
        }, 1500);
      }
    });
  });
})(jQuery);
