/* jshint es3:true */
(function($, arc){
    var languageDialog, dialogOptions = {
        width: '800px',
        closable: false,
        modal: true
    };

  /*
   * Remove empty .dialog-foot from dialogs
   */
   function removeEmptyDialogFooters() {
     if ($('.dialog-foot').is(':empty')) {
        $('.dialog-foot').remove();
     }
   }

/*
 * Switch continent tabs to option select for mobile.
 */
 function enableMobileNavContinents() {
    $('.continents-navigation-mobile').on('change', function() {
        var continent = this.value;
        var anchor = $('ul.nav.nav-tabs').find('a');
        $(anchor).each(function(){
            var hrefVal = $(this).attr('href');
            if (hrefVal == continent) {
                $(this).trigger('click');
            }
        });
    });
 }

 /*
  * If the abbreviated locale is Russian or Chinese then set the lang to said language
  * to allow us to set the correct font stacks for these languages.
  */
  function enableFontStackSwitch() {
      // Get the Language name, remove the white space, then remove the first 3 characters
      var localeLanguage = $('.locale-select-link').find('span').text().replace(/\s/g, '').substring(3);

      // Russian
      if(localeLanguage == 'Русский') {
          $('html').attr('lang', 'ru');
      }
      // Chinese
      else if(localeLanguage == '中文') {
          $('html').attr('lang', 'zh-cn');
      }
  }
  enableFontStackSwitch();

  $i.showFirstVisitDialogs = function() {
      $.get('/topix/i18n/language', function(data) {
          _.defer(function(){
              languageDialog = $i.dialog($.extend({}, dialogOptions, {
                bodySelector: $('#language-dialog-content', data),
                buttons: false,
                open: function() {
                    $i.initializeFirstVisitDialogs();
                    removeEmptyDialogFooters();
                }
              }));
          });
      });
  };

  $('.locale-select-link').on('click', function(event) {
      event.preventDefault();
      $i.showFirstVisitDialogs();
  });

  $i.initializeFirstVisitDialogs = function() {
      var countryDialog, locale;

      if (!$('.dialog-overlay').is(':visible')) {
          $('<div class="dialog-overlay"></div>').appendTo('body').show();
      }

      function countryDialogOnLoad() {
          var countrySuggestionEngine, countrySearchInput = $('.country-search-input'), countrySelect = $('#country-selector'),
              forceCountry = $('#i18n-form').find('[name="country"]').val();
          if (forceCountry !== '')
          {
              // don't let the IO's or repairers without republisher role choose a country
              selectCountry(forceCountry, 'search');
              return;
          }
          $('#country-dialog').find('.tabbable li').on('shown', function() {
            this.resize();
          }.bind(this));

          function selectCountry(countryCode, countrySelectionMethod) {
              countryDialog.close();

              function submitCookies() {
                  var form = $('#i18n-form');
                  form.find('[name="topixLocale"]').val(locale);
                  form.find('[name="country"]').val(countryCode);
                  form.find('[name="countrySelectionMethod"]').val(countrySelectionMethod);
                  form.find('[name="back"]').val(window.location.href);
                  form.submit();
              }

              if ($.cookie('COOKIE_CONSENT')) {
                  submitCookies();
              }
              else {
                  $i.openCookiePolicyDialog(locale, submitCookies, dialogOptions);
              }
          }

          this.box.find('.country').on('click', function() {
              selectCountry($(this).attr('data-country-code'), $('#top-choices').is(':visible') ? 'topChoicesTab' : 'continentTab');
          });

          countrySuggestionEngine = new Bloodhound({
              name: 'countries',
              local: function() {
                  /*
                   * Converts options from select#country-selector to an array of objects that have the 'countryName',
                   * the 'alternativeSpellings', the 'relevancyBooser' for sorting, and the 'countryCode'.
                   */
                  return countrySelect.find('option').map(function() {
                      var option = $(this), optionValue = option.text();
                      return $i.isEmpty(optionValue) ? null : {
                          countryCode: option.attr('value'),
                          countryName: option.text(),
                          alternativeSpellings: option.attr('data-alternative-spellings'),
                          relevancyBooster: option.attr('data-relevancy-booster')
                      };
                  }).get();
              },
              sorter: function(left, right) {
                  // -1: left is bigger, 0: equal, 1: right is bigger
                  var leftName = left.countryName.toLocaleLowerCase(),
                      rightName = right.countryName.toLocaleLowerCase(),
                      inputValue = countrySearchInput.val().toLocaleLowerCase();
                  if (left.countryName === right.countryName) {
                      return 0;
                  }
                  else if ((leftName.indexOf(inputValue) === 0) !== (rightName.indexOf(inputValue) === 0)) {
                      // If the country name started with the typed value it should come first.
                      return leftName.indexOf(inputValue) === 0 ? -1 : 1;
                  }
                  else if (leftName.indexOf(inputValue) !== rightName.indexOf(inputValue)) {
                      // If the country name contains the input anywhere it should come first.
                      return leftName.indexOf(inputValue) > -1 ? -1 : 1;
                  }
                  else if (left.relevancyBooster !== right.relevancyBooster) {
                      // Countries with higher relevancy should come first.
                      return right.relevancyBooster - left.relevancyBooster;
                  }
                  else {
                      // Fallback to basic string comparison.
                      return leftName < rightName ? -1 : 1;
                  }
              },
              datumTokenizer: function(d) {
                return _.union(Bloodhound.tokenizers.whitespace(d.countryName), Bloodhound.tokenizers.whitespace(d.alternativeSpellings));
              },
              queryTokenizer: Bloodhound.tokenizers.whitespace
          });

          countrySuggestionEngine.initialize();

          countrySearchInput.typeahead({
              minLength: 1,
              highlight: true
          }, {
              name: 'countries',
              source: countrySuggestionEngine.ttAdapter(),
              displayKey: 'countryName'
          }).on('typeahead:selected', function(event, suggestion, datasetName) {
              selectCountry(suggestion.countryCode, 'search');
          });

          $('.continent').on('click', function(){
              var countryList = $('#country-list');
              countryList.empty();
              var continent = $(this).attr('data-continent');
              var countries = window.getCountriesByContinent(continent);
              var divClosed = false;
              var countryListContents = '<div class="row-fluid">';
              for(i=0;i<countries.length;i++){
                  divClosed = false;
                  var countryLine = '<span class="span3 country" data-country-code="'+countries[i].code+'"><div class="country flag-32-'+countries[i].code+'"></div>&nbsp;&nbsp;'+countries[i].name+'</span>';
                  if ((i + 1) % 4 === 0) {
                      countryListContents += countryLine + '</div>';
                      divClosed = true;
                      if (countries.length - 1 > i) {
                          countryListContents += '<div>';
                      }
                  } else {
                      countryListContents += countryLine;
                  }
              }

              if (!divClosed) {
                  countryListContents += '</div>';
              }

              countryList.append(countryListContents);
              _.defer($.proxy(countryDialog.resize, countryDialog));
          });
      }

      languageDialog.$el.find('.language').on('click', function() {
          // Close language dialog.
          languageDialog.close();

          locale = $(this).attr('data-locale-code');
          // Open country dialog.
          countryDialog = $i.dialog($.extend({}, dialogOptions, {
              buttons: false,
              data: { 'locale': locale, 'topixLocale': locale },
              minHeight: '600',
              title: $i.i18n.get('country.select'),
              url: "/topix/i18n/country #country-dialog",
              width: '80%',
              open: function() {
                  removeEmptyDialogFooters();
              },
              load: function() {
                  countryDialogOnLoad.call(this);
                  enableMobileNavContinents();
                  // User may have changed their language. Update the dialog title.
                  this.box.find('.dialog-head h2').text(this.box.find('.country-dialog-title-text').text());
                  this.box.find('.ui-autocomplete-input').text(this.box.find('.country-search-placeholder-text').text());
              }
          }));
      });

      (function() {
          var titles = [], i = 0, langHeading = languageDialog.$el.find('.dialog-head h2');
          $('.language').each(function(){
              titles.push($(this).attr('data-title'));
          });

          function scroll() {
              // increment counter for next time
              i++;

              // reset to beginning if at end of language list
              if(i >= titles.length) {
                  i = 0;
              }

              // fade out last language and fade in next in the list
              langHeading.fadeOut(200, function() {
                  langHeading.text(titles[i]).fadeIn(200);
              });
          }
          scroll();
          window.setInterval(scroll, 2000);
      })();
  };
})(jQuery, arc);
