(function($, arc) {
  var dropdowns = [];

  $(document).ready(function() {
    $('.dropdown').each(function() {
      var $dropdown = $(this);
      if ($dropdown.parents().eq(1).hasClass('dropdown') || $dropdown.data('auto-instance-disable')) {
        return true;
      }

      dropdowns.push($dropdown.dropdown());
    });
  });

  function Dropdown($dropdown, options) {
    var self = this;
    this.options = options || {};
    this.$window = $(window);
    this.$content = $('#content');
    this.index = dropdowns.length + 1;
    this.$dropdown = $dropdown.addClass('dropdown-js dropdown-root');
    this.$wrapper = this.$dropdown.children('ul');
    this.$wrapper.find('ul').parent().addClass('dropdown dropdown-js');
    this.openItems = [];
    this.isMultipleChoice = this.$dropdown.hasClass('dropdown-choice-multiple');

    this.$dropdown.on('click', function(e) {
      self.toggle.call(self, $(this), e);
    });
    
    this.$wrapper.find('> li > span, > li > a').on('click', function() {
      var $item = $(this).parent();

      if (!self.isMultipleChoice) {
        $item.siblings().removeClass('select-selected');
      }

      $item.toggleClass('select-selected');

      if (typeof self.options.select === 'function') {
        self.options.select.call(self, $item, $item.hasClass('select-selected'));
      }
    });

    return this;
  }

  Dropdown.prototype.toggle = function($item, e) {
    if ($item.hasClass('dropdown-open')) {
      this.close($item, e);
    } else {
      this.open($item);
    }
  };

  Dropdown.prototype.open = function($item) {
    this.openItems.push($item.data('index', this.openItems.length + 1));
    // close other dropdowns
    this.closeOthers();
    $item.addClass('dropdown-open');
    this.setContentSpacing();
    this.calcHorizontalFlip();
    this.calcVerticalFlip();

    if (typeof this.options.open === 'function') {
      this.options.open.call(this, $item);
    }
  };

  Dropdown.prototype.close = function($item, event) {
    var isRootDropdown = false;

    if (event) {
      var $target = $(event.target);
      if (!$target.hasClass('dropdown-root') && !$target.parent('.dropdown-root').length) {
        return false;
      }
    }

    $item.removeClass('dropdown-open');
    this.removeContentSpacing();

    if ($item.is(this.$dropdown)) {
      isRootDropdown = true;
    }

    $.each(this.openItems, function(i, $openItem) {
      if ($openItem.is($item)) {
        $item.data('index', '');
        this.openItems.slice($item.data('index'), 1);
      }

      if (isRootDropdown) {
        $item.removeClass('dropdown-open');
      }
    }.bind(this));

    if (typeof this.options.close === 'function') {
      this.options.close.call(this, $item, event);
    }
  };

  Dropdown.prototype.closeOthers = function() {
    $.each(dropdowns, function(i, dropdown) {
      if (dropdown.index !== this.index) {
        $.each(dropdown.openItems, function(j, $item) {
          dropdown.close($item);
        }.bind(this));
      }
    }.bind(this));
  };

  Dropdown.prototype.calcHorizontalFlip = function() {
    this.$dropdown.removeClass('dropdown-flip-horizontal');

    if (this.$wrapper.offset().left + this.$wrapper.outerWidth() >= this.$window.outerWidth() - 25) {
      this.$dropdown.addClass('dropdown-flip-horizontal');
    }

    if (this.$wrapper.offset().left <= 25) {
      this.$dropdown.removeClass('dropdown-flip-vertical');
    }
  };

  Dropdown.prototype.calcVerticalFlip = function() {
    this.$dropdown.removeClass('dropdown-flip-vertical');

    if (this.$wrapper.offset().top + this.$wrapper.outerHeight() >= $(document).height() - 25) {
      this.$dropdown.addClass('dropdown-flip-vertical');
    }

    if (this.$wrapper.offset().top <= 25) {
      this.$dropdown.removeClass('dropdown-flip-vertical');
    }
  };

  Dropdown.prototype.setContentSpacing = function() {
    var $contentHeight = this.$content.outerHeight() + $('.header').eq(0).outerHeight(),
        $wrapperHeight = this.$wrapper.outerHeight();

    if ($wrapperHeight > $contentHeight) {
      this.$content.css('padding-bottom', $wrapperHeight - $contentHeight);
    }
  };

  Dropdown.prototype.removeContentSpacing = function() {
    this.$content.css('padding-bottom', '');
  };

  $.extend(arc, {
    dropdown: Dropdown,
    dropdowns: dropdowns
  });

  $.fn.dropdown = function(options) {
    var $dropdown = $(this);
    if (!$dropdown.length) {
      return false;
    }

    if ($dropdown.length > 1) {
      var dropdowns = [];
      $dropdown.each(function(i, dropdown) {
        dropdowns.push(new arc.dropdown($(dropdown), options));
      });
      return dropdowns;
    }

    return new arc.dropdown($(this), options);
  };
})(jQuery, arc);
