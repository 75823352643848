/* jshint es3:true */

(function($, arc) {

  arc.initialiseActivityStream = function(url, omitCampaignFilterPreference) {
    var eventNamespace = ".activitystream",
        heightSet = false,
        waiting = true,
        start = 0,
        icons = {
          'notice' : 'info-circle',
          'document' : 'file',
          'campaign' : 'exclamation-triangle',
          'none' : 'check'
        },
        tooltips = {
          notice : $i.i18n.get('user.activitystream.notice'),
          document : $i.i18n.get('user.activitystream.document'),
          campaign : $i.i18n.get('user.activitystream.campaign'),
          name : ''
        },
        mainActivityStream = $('#activity-stream-main').removeClass('hide').children('ul'),
        mainLoadingItem = mainActivityStream.find('li:first'),
        streamItemTemplate = '<li class="stream-item {{type}}"><a href="{{href}}"><i title="{{tooltip}}" class="fa fa-{{icon}}"></i> <span class="activity-stream-item-title">{{display}}</span></a></li>';
        // filteredItemTemplate = '<li class="stream-item stream-filtered alert alert-error" href="#"></li>';

    loadActivityStream();

    function loadActivityStream() {
       if (mainActivityStream.children().length === 1) {
          loadItems();
       }

       // Load items when scrolling to the bottom.
       mainActivityStream.on('scroll' + eventNamespace, function() {
          var container = $(this), scrollPosition = container.scrollTop() + $(this).outerHeight(), divTotalHeight = container.prop('scrollHeight');
          if (scrollPosition >= (divTotalHeight - 20) && waiting === false) {
             mainLoadingItem.fadeIn(500);
             loadItems();
          }
       });
    }

    function clearActivityStream() {
       heightSet = false;
       waiting = true;
       start = 0;
       mainActivityStream.empty().css("height", "auto").css('overflow-y', 'hidden').append(mainLoadingItem);
       mainLoadingItem.fadeIn(500);
    }

    function addItem(item) {
       item = $($.mustache(streamItemTemplate, _.extend(item, {
          icon : icons[item.type.toLowerCase()],
          tooltip : tooltips[item.type.toLowerCase()]
       })));
       tippy(item.find('i')[0]);
       item.insertBefore(mainLoadingItem);
       // the tooltips need to be placed to the right of the icons as that's
       // the only gap where they'll reliably show
       $('#panel-stream-main a.stream-item i').tooltip({
          placement : 'right'
       });
       return item;
    }

    // function addFilteredItem() {
    //     var filterItem;
    //     if (mainActivityStream.find('.stream-filtered').length === 0) {
    //        filterItem = $(filteredItemTemplate).prependTo(mainActivityStream);
    //        filterItem.html($i.i18n.get('vehicle.lookup-input.activity-stream-filtered'));
    //        filterItem.on('click', function(event) {
    //           event.preventDefault();
    //
    //           // onShown callback isn't being called when .clickover('show'); is
    //           // invoked. Doing it manually.
    //           loadActivityStreamConf();
    //        });
    //     }
    //  }

    function loadItems() {
       var firstPage = (start === 0), pageSize = 9;
       waiting = true;
       $.ajax({
          /*
           * Disabled caching as viewing a document may remove it from the
           * stream (dependent on user preference). To reduce the server load
           * we may wish to allow caching with a function to force a refresh
           * when necessary.
           */
          cache : false,
          dataType : "json",
          url : url,
          data : {
             start : start,
             amount : pageSize
          }
       }).done(function(result, textStatus, jqXHR) {
          var items = $i.isObject(result) ? result.items : null, filterItem;
          mainLoadingItem.stop(true, true).hide();
          if (_.isArray(items) && items.length > 0) {
            //  if (result.filtered) {
            //     addFilteredItem();
            //  }
             _.each(items, addItem);
          } else {
             if (firstPage) {
                addItem({
                   "id" : 0,
                   "display" : $i.i18n.get('vehicle.lookup-input.activity-stream-filter'),
                   "type" : "none"
                });
             }
             // Stop listening for the scroll events as there are no more
             // items to show.
             mainActivityStream.off('scroll' + eventNamespace);
          }

          if (heightSet === false) {
             // TODO Do this with CSS.
             mainActivityStream.height(mainActivityStream.height());
             heightSet = true;
             if (items !== null && firstPage && items.length < pageSize) {
                // First page returns less than the requested number of items. This means that there are no more items, we can disabled scrolling.
                mainActivityStream.css('overflow-y', 'hidden');
             }
             else {
                // There might be more items, let the user try scrolling.
                mainActivityStream.height(mainActivityStream.height() - 1);
                mainActivityStream.css('overflow-y', 'scroll');
             }
          }
          waiting = false;
       });
       start += 9;
    }

    // /////////////////////// Activity stream configuration
    // /////////////////////////

    function loadActivityStreamConf() {
       // workaround for color
       $('.popover-title').css("color", "black");
       $('.popover-content').css("color", "black");
       var searchUrl = $i.contextPath + '/activitystream/loadConfiguration';
       optionSelected = '<label class="checkbox"><input type="checkbox" class="parentGroupId" value="{{id}}" checked="checked" />{{name}}</label>';
       option = '<label class="checkbox"><input type="checkbox" class="parentGroupId" value="{{id}}" />{{name}}</label>';
       $.ajax({
          url : searchUrl,
          success : function(data) {
             var removeReadDocumentsFromAS = data.removeReadDocumentsFromAS,
                 removeReadCampaignsFromAS = data.removeReadCampaignsFromAS,
                 enableActivityStreamFilter = data.enableActivityStreamFilter,
                 filter = data.filter,
                 removeReadDocumentsCheckBox = $('#removeReadDocumentsFromAS'),
                 removeReadCampaignsCheckBox = $('#removeReadCampaignsFromAS'),
                 filterCheckBox = $('#enableActivityStreamFilter');

             filterCheckBox.prop('checked', enableActivityStreamFilter);
             removeReadDocumentsCheckBox.prop('checked', removeReadDocumentsFromAS);
             removeReadCampaignsCheckBox.prop('checked', removeReadCampaignsFromAS);

             if (_.isArray(filter) && filter.length > 0) {
                $('#parentGroupIds').empty();
                _.each(filter, addFilterItem);
             }
             $('#stream-configuration-loading').hide();
             if(omitCampaignFilterPreference)
             {
                 $('#read-campaigns-filter').hide();
             }
             showFilter();
             $('#stream-configuration-loaded').show();

             $('#enableActivityStreamFilter').click(showFilter);
             $('#activityStreamConfUpdateBtn').click(configurationUpdate);
             // the content has changed so reposition
             updateFilteredItem();
          },
          dataType : 'json',
          cache : false
       });
    }

    function addFilterItem(item) {
       var filterItem;
       if (item.selected) {
          filterItem = $($.mustache(optionSelected, item));
       } else {
          filterItem = $($.mustache(option, item));
       }

       $('#parentGroupIds').append(filterItem);
    }

    function showFilter() {
       if ($('#enableActivityStreamFilter').is(':checked')) {
          $('#document-parent-groups-selector').show();
       } else {
          $('#document-parent-groups-selector').hide();
       }
    }

    function updateFilteredItem() {
       var filtersEnabled = $('#enableActivityStreamFilter').is(':checked'),
           filteredItem = mainActivityStream.find('.stream-filtered'),
           hasFilteredItem = filteredItem.length > 0;
       if (filtersEnabled && !hasFilteredItem) {
          // addFilteredItem();
       } else if (!filtersEnabled && hasFilteredItem) {
          filteredItem.remove();
       }
    }

    function configurationUpdate() {
       var updateUrl = $i.contextPath + '/activitystream/configure',
           removeReadDocumentsFromAS = $('#removeReadDocumentsFromAS').is(':checked'),
           removeReadCampaignsFromAS = $('#removeReadCampaignsFromAS').is(':checked'),
           enableActivityStreamFilter = $('#enableActivityStreamFilter').is(':checked'),
           parentGroupIds = $('.parentGroupId:checked').map(function() {
             return this.value;
           }).get();
       $.ajax({
          type : "POST",
          url : updateUrl,
          data : {
             removeReadDocumentsFromAS : removeReadDocumentsFromAS,
             removeReadCampaignsFromAS : removeReadCampaignsFromAS,
             enableActivityStreamFilter : enableActivityStreamFilter,
             parentGroupIds : parentGroupIds
          },
          dataType : 'json',
          cache : false,
          success : function(data) {
             if (data.success) {
                clearActivityStream();
                loadActivityStream();
             }
          }
       });
    }
  };

})(jQuery, arc);
