(function($, arc) {
  function Tabs(tabs, options) {
    this.options = options || {};
    this.tabs = [];
    this.$tabs = arc.utils.isJqueryElement(tabs) ? tabs : $('<ul></ul>');
    this.$content = $('#content');
    this.target = '';
    this.$target = $();
    this.enableFirstTab = this.options.enableFirstTab === false ? false : true;
    this.requesting = null;

    var tabsTarget = this.$tabs.data('target');

    if (tabsTarget) {
      var idTarget = '#' + tabsTarget,
          classTarget = '.' + tabsTarget;

      if ($(idTarget).length) {
        this.target = idTarget;
      } else if ($(classTarget).length) {
        this.target = classTarget;
      }
    }

    $.each(this.options, function(key) {
      switch (key) {
        case 'callback': {
          if (arc.utils.isFunction(this.options[key])) {
            this.callback = this.options[key];
          }
          break;
        }
        case 'id': {
          if (arc.utils.isString(this.options[key])) {
            this.$tabs.attr('id', this.options[key]);
          }
          break;
        }
        case 'className': {
          if (arc.utils.isString(this.options[key])) {
            this.$tabs.addClass(this.options[key]);
          } else {
            this.$tabs.addClass('tabs');
          }
          break;
        }
        case '$tabTarget': {
          if (arc.utils.isJqueryElement(this.options[key]) && this.options[key].length) {
            this.$tabs.prependTo(this.options[key]);
            break;
          }

          this.$tabs.prependTo(this.$content);
          break;
        }
        case '$contentTarget': {
          if (arc.utils.isJqueryElement && this.options[key].length) {
            this.target = this.options[key];
            break;
          }
        }
      }
    }.bind(this));

    this.$target = $(this.target);
    if (!this.$target.length) {
      this.$target = $('<div/>').insertAfter(this.$tabs);
    }

    if (arc.utils.isJqueryElement(tabs)) {
      tabs.children().each(function(i, tab) {
        var $tab = $(tab);
        this.createTab($tab);

        // set active tab
        if ($tab.hasClass('active')) {
          if (this.enableFirstTab) {
            this.enableTab(this.tabs[i]);
            return;
          }

          this.tabs[i].active = true;
        }
      }.bind(this));
    } else {
      $.each(tabs, function(i, tab) {
        this.createTab(tab);
      }.bind(this));
    }

    if (!this.$tabs.children('.active').length && this.enableFirstTab) {
      this.enableTab(this.tabs[0]);
    }

    if (this.options.hasOwnProperty('initialised') &&
        typeof this.options.initialised === 'function') {
      this.options.initialised.call(this, this.tabs);
    }

    return this;
  }

  Tabs.prototype.createTab = function(tab) {
    var $tab = null,
        tabObj = {},
        $target;

    // if tab already exists assign as $tab and empty {} with $tab key
    // else tab is an object, create LI tab and assign empty {} with tab options and $tab key
    if (arc.utils.isJqueryElement(tab)) {
      $tab = tab;
      tabObj = $.extend(tabObj, {$tab: $tab});
    } else if (arc.utils.isJqueryElement(tab.$tab) && tab.$tab.length) {
      $tab = tab.$tab;
      tabObj = $.extend(tabObj, tab);
    } else {
      $tab = $('<li/>');
      tabObj = $.extend({}, tab, {$tab: $tab});
    }

    tabObj.active = false;
    tabObj.target = $tab.data('target');

    // if the data-target attribute exists (it might not!)
    // and if the data-target attribute is NOT a URL (because jQuery errors if we attempt to search for a DOM element
    // with slashes in it's string).
    if (tabObj.target && tabObj.target.indexOf('/') === -1) {
		  $target = $('#' + tabObj.target);
	  }

    if ($target && $target.length) {
      tabObj.target = $target.hide();
    }

    if (typeof tabObj.blockRequest === 'undefined') {
      tabObj.blockRequest = false;
    }

    if (typeof tabObj.blockPage === 'undefined') {
      tabObj.blockPage = false;
    }

    // if id in tab add id
    if (arc.utils.isString(tabObj.id)) {
      $tab.attr('id', tabObj.id);
    }

    // if className in tab add class
    if (arc.utils.isString(tabObj.className)) {
      $tab.addClass(tabObj.className);
    }

    if (tabObj.disabled) {
      $tab.attr('disabled', 'disabled');
    }

    if (arc.utils.isString(tabObj.text)) {
      $tab.html(tabObj.text);
    }

    $tab.on('click', this.enableTab.bind(this, tabObj));
    this.tabs.push(tabObj);

    // if generating tabs wrapper add this $tab to wrapper
    if (!this.$tabs.length) {
      this.$tabs.append($tab);
    }
  };

  Tabs.prototype.enableTab = function(activeTab) {
    var target = arc.utils.isString(activeTab.target) ? activeTab.target : activeTab.$tab.data('target');
    if (this.requesting) return;

    if (arc.utils.isFunction(activeTab.preSelected)) {
      activeTab.preSelected(activeTab);
    }

    setTimeout(function() {
      if (target) {
        $.each(this.tabs, function(i, tab) {
          tab.active = false;
          tab.$tab.removeClass('active');
        });

        activeTab.$tab.addClass('active');
        activeTab.active = true;

        if (arc.utils.isString(activeTab.target)) {
          if (!activeTab.blockRequest) {
            this.request(target, activeTab);
          } else if (!activeTab.blockPage) {
            window.location.href = target;
          }
        } else {
          $.each(this.tabs, function(i, tab) {
            tab.target.hide();
          });

          activeTab.target.show();

          if (arc.utils.isFunction(activeTab.selected)) {
            activeTab.selected(activeTab);
          }

          if (this.callback) {
            this.callback(activeTab);
          }
        }
      }
    }.bind(this), 0);
  };

  Tabs.prototype.request = function(target, tab) {
    if (this.requesting) return;

    this.requesting = true;
    this.addLoading(function() {
      $.get(target, function(data) {
        this.requesting = false;
        this.$target.html($(data).find(this.target).html());
        this.removeLoading();

        if (arc.utils.isFunction(tab.selected)) {
          tab.selected(tab);
        }

        if (this.callback) {
          this.callback();
        }
      }.bind(this));
    });
  };

  Tabs.prototype.addLoading = function(cb) {
    if (this.$target.prev('.loading-spin-wrapper').length) {
      return;
    }

    if ($.browser.msie && $.browser.versionNumber === 8) {
      this.$target.hide();
      $('<div class="align-center soft-double-top loading-spin-wrapper"><img class="loading-spin" src="' + arc.contextPath + '/images/arc/ie8-spinner.gif' +'"/></div>').insertBefore(this.$target);

      if (typeof cb === 'function') {
        cb.call(this);
      }
      return;
    }

    this.$target.fadeOut(200, function() {
      $('<div class="align-center soft-double-top loading-spin-wrapper"><span class="loading-spin"></span></div>').insertBefore(this.$target);

      if (typeof cb === 'function') {
        cb.call(this);
      }
    }.bind(this));
  };

  Tabs.prototype.removeLoading = function(cb) {
    $(this.target).prev('.loading-spin-wrapper').remove().end().fadeIn(200, function(cb) {
      if (typeof cb === 'function') {
        cb.call(this);
      }
    }.bind(this));
  };

  function tabs(tabs, options) {
    return new Tabs(tabs, options);
  }

  $.extend(true, arc, {
    tabs: tabs
  });
})(jQuery, arc);
