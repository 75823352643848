(function($) {

  function InfiniteScrolling(options) {
    this.window = $(window);
    this.options = options || {};
    this.loaderContext = this.options.loaderContext.length ? $(this.options.loaderContext) : $('body');
    this.scrollContext = this.options.scrollContext.length ? $(this.options.scrollContext) : $(document);
    this.limit = this.options.limit || 10;
    this.load = typeof this.options.load === 'function' ? this.options.load : null;
    this.data = this.options.data;
    this.items = 0;
    this.loader = null;
    this.currentscrollHeight = 0;

    if (!this.data) {
      return;
    }

    this.request();
    this.scrollContext.on('scroll', this.scrolling.bind(this));

    return this;
  }

  InfiniteScrolling.prototype.scrolling = function() {
    this.scrollHeight = this.loaderContext.height();
    this.scrollPos = Math.floor(this.scrollContext.height() + this.scrollContext.scrollTop());
    this.isBottom = this.scrollHeight - 150 < this.scrollPos;

    if (this.isBottom && this.currentscrollHeight < this.scrollHeight) {
      this.request();
      this.currentscrollHeight = this.scrollHeight;
    }
  };


  InfiniteScrolling.prototype.request = function() {
    var data = this.data.slice(this.items, this.items + this.limit);
    this.items = this.items + data.length;
    this.addLoader();

    if (this.load) {
      $.each(data, function(i, item) {
        this.load(item);
      }.bind(this));
    }

    this.removeLoader();
  };

  InfiniteScrolling.prototype.requestAll = function(cb) {
    var data = this.data.slice(this.items, this.data.length);
    this.items = this.data.length;
    this.addLoader();

    if (this.load) {
      $.each(data, function(i, item) {
        this.load(item);
      }.bind(this));
    }

    this.removeLoader();

    if (typeof cb === 'function') {
      cb();
    }
  };

  InfiniteScrolling.prototype.addLoader = function() {
    this.loader = $('<div/>').addClass('loader').append('<i class="fa fa-spinner fa-spin"></i>');
    this.loaderContext.append(this.loader);
  };

  InfiniteScrolling.prototype.removeLoader = function() {
    if (this.loader) {
      this.loader.remove();
      this.loader = null;
    }
  };

  $.fn.infiniteScrolling = function(options) {
    return new InfiniteScrolling($.extend({loaderContext: $(this)}, options));
  };

})(jQuery);
