(function ($, arc) {

    function SimpleTab(options) {
        this.options = options || {};
        this.$tabs = options.$tabs;
        this.$anchors = this.$tabs.find("a[data-tab]");
        this.$tabs = {};
        this.tabsIndex = {};
        this.closures = options.closures;
        this.onchange = options.onchange;
        this.parent = options.parent;
        this.addEvents();
    }

    SimpleTab.prototype.handleClick = function (id) {
        this.$currentAnchor.parent().removeClass("active");
        this.$currentAnchor = $("[data-tab='" + id + "']");
        this.$currentAnchor.parent().addClass("active");
        this.$currentTab.hide();
        var $tab = this.$tabs[this.getIdWithoutDelimeters(id)];
        $tab.fadeIn();
        this.$currentTab = $tab;
        if (this.closures !== undefined && this.closures[this.currentIndex] !== undefined) {
            this.closures[this.currentIndex]();
        }
        if (this.onchange !== undefined) {
            this.onchange(this.parent);
        }
        this.currentIndex = this.tabsIndex[this.getIdWithoutDelimeters(id)];
    };

    SimpleTab.prototype.addEvents = function () {
        var thiz = this;
        var iter = 0;
        this.$anchors.each(function() {
            var id = $(this).data("tab");
            var $tab = $(id);
            var parent = $(this).parent();
            if (parent.hasClass("active"))
            {
                thiz.$currentTab = $tab;
                thiz.$currentAnchor = $(this);
                thiz.currentIndex = iter;
            }
            thiz.$tabs[thiz.getIdWithoutDelimeters(id)] = $tab;
            thiz.tabsIndex[thiz.getIdWithoutDelimeters(id)] = iter;
            iter++;

            $(this).on('click', function() {
                var id = $(this).data("tab");
                if (thiz.$currentAnchor.data("tab") === id) {
                    return;
                }
                thiz.handleClick(id);
            });
        });
    };

    SimpleTab.prototype.getIdWithoutDelimeters = function (id) {
        return id.replace('-', '').replace('#', '');
    };

    SimpleTab.prototype.getActiveTab = function () {
        return this.$currentTab;
    };

    SimpleTab.prototype.setActiveTab = function (id) {
        this.handleClick(id);
        this.clickActiveTab();
    };

    SimpleTab.prototype.clickActiveTab = function() {
        //.click() added for compatibility for code that's listening to the click event listener
        this.$currentAnchor.click();
    };

    $.extend(arc, {
        simpleTab: SimpleTab
    });
})(jQuery, arc);
