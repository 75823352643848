(function($, $i, arc) {

  function Select($select, options) {
    if (!$select.length) return false;
    var self = this;
    this.$select = $select;
    this.$title = this.setTitle();
    this.$wrapper = this.setWrapper();
    this.options = options || {};
    this.data = this.options.data;
    this.$container = $('#content');
    this.$document = $(document);
    this.$select.addClass('select-js');

    if (!this.$select.find('.select-active').length) {
      this.setPlaceholder();
    }

    if (this.data && this.data.length) {
      this.add();
    }

    if (this.$wrapper === null || !this.$wrapper.children().length) {
      this.$select.addClass('select-empty');
    }

    this.$title.on('click', function(e) {
      this.toggle.call(this, e);
    }.bind(this));

    this.$select.on('click', 'li', function() {
       self.select.call(self, $(this));
    });
  }

  Select.prototype.toggle = function(e) {
    if (this.$select.hasClass('select-open')) {
      this.close(e);
    } else {
      this.open();
    }
  };

  Select.prototype.open = function() {
    if (this.$select.attr('disabled')) {
      return false;
    }
    var pb = 0;
    setTimeout(function() {
      this.$document.on('click.arc-select', function(event) {
        this.close();
      }.bind(this));
    }.bind(this), 0);
    this.$select.addClass('select-open');

    if (this.$wrapper) {
      this.$wrapper.children('li').each(function() {
        pb += $(this).outerHeight();
      });

      this.$container.css('padding-bottom', pb);
    }
  };

  Select.prototype.close = function(e) {
    if (e && !$(e.target).hasClass('select')) {
      return false;
    }

    this.$document.off('click.arc-select');
    this.$select.removeClass('select-open');
    this.$container.css('padding-bottom', '');
  };

  Select.prototype.add = function() {
    var self = this;
    this.setWrapper();

    if (this.data.length) {
      this.$select.removeClass('select-empty');
    }

    $.each(this.data, function() {
      var text = this.text ? this.text : '',
          description = this.description ? this.description: '',
          selected = this.selected ? 'select-active' : '',
          src = this.imageSrc ? this.imageSrc : '',
          $item = $('<li/>').addClass(selected).data('item', this),
          $itemContentWrapper = $('<div/>').addClass('select-content'),
          $title = text ? $('<p/>', {text: text}).addClass('select-title') : '',
          $description = description ? $('<span/>').addClass('select-description').append(description) : '',
          $img = src ? $('<img/>', {src: src}) : '',
          $itemImgWrapper = src ? $('<div/>').addClass('select-image') : '';

      if ($itemImgWrapper) {
        $item.append($itemImgWrapper.append($img));
      }

      self.$wrapper.append($item.append($itemContentWrapper.append($title, $description)));
    });
  };

  Select.prototype.select = function($item) {
    this.setWrapper();

    var $selectedItem = $item.children().clone();
    $item.addClass('select-active').siblings('li.select-active').removeClass('select-active');
    this.$title.html($selectedItem);

    if (!this.$wrapper.children('li:not(.select-active)').length) {
      this.$select.addClass('select-empty');
    }

    this.close();

    if (this.options.selected && typeof this.options.selected === 'function') {
      this.options.selected.call(this, $item);
    }
  };

  Select.prototype.setPlaceholder = function() {
    this.$title.text(this.options.placeholder ? this.options.placeholder : '');
  };

  Select.prototype.setWrapper = function() {
    if (this.$wrapper === null) {
      this.$wrapper = this.$select.append('<ul></ul>').children('ul');
      return;
    }

    if (this.$select.children('ul').length) {
      return this.$select.children('ul').eq(0);
    }

    return null;
  };

  Select.prototype.setTitle = function() {
    if (this.$select.children('div').length) {
      return this.$select.children('div');
    }

    this.$select.prepend('<div></div>');
    return this.$select.children('div');
  };

  $.extend(arc, {
    select: Select
  });

  $.fn.extend({
    select: function(options) {
      return new Select($(this), options);
    }
  });

})(jQuery, $i, arc);
