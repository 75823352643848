(function ($, arc) {

    //CONSTRUCTOR
    arc.userSettings = function (options) {
    var spinner = new Spinner({lines: 8, length: 0, width: 8, radius: 10}).spin();
            $(spinner.el).css({'margin-left': '50%'});
            $(spinner.el).css({'margin-top': '70px'});
            $(spinner.el).css({'padding-bottom': '50px'});
            options.$spinner.html("");
            options.$spinner.append(spinner.el);

            this.$userSettings = options.$userSettings;
            this.$settingsSpinner = options.$spinner;
            this.loadHtml();
    };

    arc.userSettings.prototype.loadHtml = function () {
        var thiz = this;

        $.ajax({
            url: $i.defaultContextPath + "/technical/assistance/settingsForm",
            dataType: "html",
            success: function (data) {
                    thiz.$settingsSpinner.hide();
                    thiz.$userSettings.html(data);
                    thiz.$form = thiz.$userSettings.find('form');
                    var action = thiz.$form.attr('action');
                    thiz.$applySettingsButton = thiz.$form.find('#apply-settings-button');
                    thiz.$settingsApplied = thiz.$userSettings.find("#settings-applied");
                    thiz.$settingsErrorMessage = thiz.$userSettings.find("#settings-error-message");
                    thiz.$taSwwMessage = thiz.$userSettings.find("#ta-sww-error-message");
                    thiz.$reloadAccountDataButton = thiz.$form.find('#reload-gcm-account-data');
                    thiz.$dataReloadedSucessfullyMessage = thiz.$userSettings.find("#data-reloaded");

                    thiz.$applySettingsButton.on('click', function (e) {
                        thiz.$applySettingsButton.attr("disabled", true);
                        var action = thiz.$form.attr('action');
                        thiz.namespace = action.substring(0, action.lastIndexOf("/") + 1);
                        var url = thiz.namespace + 'applyUserSettings';
                        thiz.$form.attr('action', url);
                        e.preventDefault();
                        thiz.submitForm(url, thiz.$applySettingsButton, thiz.$settingsApplied);
                    });

                    thiz.$reloadAccountDataButton.on('click', function (e) {
                        thiz.$reloadAccountDataButton.attr("disabled", true);
                        var reloadUrl = $i.defaultContextPath + "/technical/assistance/reloadData";

                        thiz.reloadAccountData(reloadUrl, thiz.$reloadAccountDataButton, thiz.$dataReloadedSucessfullyMessage);
                        setTimeout(function () {window.location.reload();}, 3000);
                    });
                }
        });
    };

    /**
     * Sends a request to reload the GCM session data for the user.
     * Display a message if request was successful or not.
     *
     * @param {string} url - the endpoint to send the request to.
     * @param {jQuery} $button
     * @param {jQuery} $message
     */
    arc.userSettings.prototype.reloadAccountData = function (url, $button, $message) {
        var thiz = this;
        $.ajax({
            type: "POST",
            dataType: "json",
            url: url,
            data:  "reload",
            success: function (data) {
                thiz.processResponse(data, $message);
            },
            error: function (data) {
                thiz.$taSwwMessage.fadeIn();
                setTimeout(function () { thiz.$taSwwMessage.fadeOut(); }, 10000);
            }
        });
    };

    arc.userSettings.prototype.submitForm = function (url, $button, $message) {
        var thiz = this;
        var formData = {};
        formData['formDraftLifetime'] = $('input[name=formDraftLifetime]:checked', '#userSettingsForm').val();
        $.each($('input[name=notificationPrefIds]:checked', '#userSettingsForm'), function (i, obj) {
            formData['notificationPrefIds['+i+']'] = $(obj).val();
        });
        $.ajax({
              type: "POST",
              dataType: "json",
              url: url,
              data:  formData,
              success: function (data) {
                  $button.attr("disabled", false);
                  thiz.processResponse(data, $message);
              },
              error: function (data) {
                  $button.attr("disabled", false);
                  thiz.$taSwwMessage.fadeIn();
                  setTimeout(function () { thiz.$taSwwMessage.fadeOut(); }, 10000);
              }
          });
        };

    arc.userSettings.prototype.processResponse = function (data, $message) {
            var thiz = this;
            if ('success' === data.status) {
                $message.fadeIn().css({'position': 'absolute', 'right': '32px'});
                setTimeout(function () {
                    $message.fadeOut();
                }, 5000);
            }
            else if ('error' === data.status) {
                thiz.$settingsErrorMessage.html(data.message);
                thiz.$settingsErrorMessage.fadeIn();
                setTimeout(function () {
                    thiz.$settingsErrorMessage.fadeOut();
                }, 10000);
            }
        };

})(jQuery, arc);
