//jQuery plugin
(function ($) {
  $.fn.uploader = function (options) {
    var settings = $.extend({
      MessageAreaText: "No files selected.",
      DefaultErrorMessage: "Unable to open this file.",
      BadTypeErrorMessage: $i.i18n.get('technical.assistance.gcm.uploader.type.unsupported'),
      acceptedFileTypes: ['7z','asc','avi','bmp','ccf','clm','dat','dbg','dlg','doc','docx','edr','exml','gif','jpeg','jpg','jva','log','m4a','mov','mp3',
        'mp4','mpeg','mpg','msi','pdf','png','ppt','pptx','psdata','s93','tiff','txt','vbf','vid','wav','wmv','wrf','xda','xls','xlsx','xml','xspf','zip','json','heic']
    }, options);
    var uploadId = 1;
    //$('.file-uploader__message-area p').text(options.MessageAreaText || settings.MessageAreaText);
    var $fileList = $('<ul class="file-list"></ul>');
    var $hiddenInputs = $('<div class="hidden-inputs hidden"></div>');

    var base;
    if ($(settings.parent.$reportDetails).length !== 0) {
      base = $(settings.parent.$reportDetails);
    }
    else if (settings.parent.$raiseTa !== undefined)
    {
      base = $(settings.parent.$raiseTa);
    }
    else if (settings.parent.$raiseEpqr !== undefined)
    {
      base = $(settings.parent.$raiseEpqr);
    }


    var $messageArea = base.find('.file-uploader__message-area');
    if (!$messageArea.next().hasClass('file-list')) {
      $messageArea.after($fileList);
    } else {
      $fileList = $messageArea.next();
    }
    $fileList.after($hiddenInputs);

    base.find('.add-file').off().on('click', function () {
      $(this).next().click();
    });

    var $fileChooser = base.find('.file-chooser__input');
    $fileChooser.off().on('change', function () {
      var file = $(this).val();
      var fileName = (file.match(/([^\\\/]+)$/)[0]);

      base.find('.file-chooser').removeClass('error');
      base.find('.error-message').remove();

      var check = checkFile(fileName);
      if(check === "valid") {
        $hiddenInputs.append($(this).clone({withDataAndEvents: true}));

        //add the name and a remove button to the file-list
        $fileList.append('<li style="display: none;"><span class="file-list__name">' + fileName + '</span><i class="fa fa-trash removal-button" data-uploadid="' + uploadId + '"></i></li>');
        $fileList.find("li:last").show(800);

        //removal button handler
        $($fileList).find($('.removal-button[data-uploadid=' + uploadId + ']')).off().on('click', function (e) {
          e.preventDefault();
          $hiddenInputs.find('input[data-uploadid="' + $(this).data('uploadid') + '"]').remove();
          //remove the name from file-list that corresponds to the button clicked
          $(this).parent().hide("puff").delay(10).queue(function () {
            $(this).remove();
            if (settings.parent.$cards !== undefined) {
              var fileSize = $fileList.find('li').length;
              if (fileSize > 0) {
                settings.parent.updateCard(settings.parent.$cards.attachments, fileSize + " " + settings.messageFilesAttached);
              } else {
                settings.parent.resetCard(settings.parent.$cards.attachments);
              }
            }
          });
          //if the list is now empty, change the text back
          /*if($('.file-list li').length === 0) {
              $messageArea.text(options.MessageAreaText || settings.MessageAreaText);
          } else {
              $messageArea.text("");
          }*/
        });
        //so the event handler works on the new "real" one
        $hiddenInputs.find('.file-chooser__input').removeClass('file-chooser__input').attr('data-uploadId', uploadId).attr('name', settings.name);

        uploadId++;
        if (settings.parent.$cards !== undefined) {
          settings.parent.updateCard(settings.parent.$cards.attachments, $fileList.find('li').length + " file(s) attached");
        }
      } else {
        //indicate that the file is not ok
        $('.file-chooser').addClass("error");
        var errorText = options.DefaultErrorMessage || settings.DefaultErrorMessage;

        if(check === "invalidExtension") {
          errorText = options.BadTypeErrorMessage || settings.BadTypeErrorMessage;
        }
        $fileChooser.after('<p class="error-message alert alert-error">'+ errorText +'</p>');
      }
    });

    var checkFile = function (fileName) {
      var accepted = "invalid", acceptedFileTypes = this.acceptedFileTypes || settings.acceptedFileTypes, regex;
      var extension = fileName.replace(/^.*\./, '');
      if (extension === fileName) {
        extension = '';
      }
      if ($.inArray(extension, acceptedFileTypes) > -1) {
        accepted = "valid";
      }
      else {
        accepted = "invalidExtension";
      }
      return accepted;
    };
  };
}(jQuery));
