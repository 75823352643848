/**
 * Utility class for filtering TOPIx and common properties.
 */
var Properties = function () {
    var commonMapSize = parseInt($('#commonPropertyMapSize').text().trim());
    var topixMapSize = parseInt($('#topixPropertyMapSize').text().trim());

    var commonSpansHidden;
    var topixSpansHidden;

    /*
    Filters elements typed in the search entry to compare them with existing values.
    */
    function filter(element) {
        var value = $(element).val().toLowerCase();
        commonSpansHidden = 0;
        topixSpansHidden = 0;
        var currentSpan;
        var resultOfSearch;

        value = value.replace(/[*]/g, "[*]");
        value = value.replace(/[.]/g, "[.]");
        value = value.replace(/[$]/g, "[$]");

        setVisibilityForCommonPropertiesSpans(currentSpan, resultOfSearch, value);
        setVisibilityForTopixPropertiesSpans(currentSpan, resultOfSearch, value);

        checkCommonNoResults();
        checkTopixNoResults();
    }

    return {
        filter: filter
    };

    /*
Checks if all spans in common properties content are hidden.
*/
    function checkCommonNoResults() {
        var noResultCommon = $('#commonNoResults');
        if (commonSpansHidden === commonMapSize) {
            noResultCommon.show();
        } else {
            noResultCommon.hide();
        }
    }

    /*
    Checks if all spans in topix properties content are hidden.
    */
    function checkTopixNoResults() {
        var noResultTopix = $('#topixNoResults');
        if (topixSpansHidden === topixMapSize) {
            noResultTopix.show();
        } else {
            noResultTopix.hide();
        }
    }

    /*
    Compares entered value with common properties content.
    Increases value for hidden spans to check if there is anything left in the common properties content.
    */
    function setVisibilityForTopixPropertiesSpans(currentSpan, resultOfSearch, value) {
        for (var j = 0; j < topixMapSize; j++) {
            currentSpan = $(".property-information > span#topix" + j);
            resultOfSearch = currentSpan.text().toLowerCase().search(value);
            if (resultOfSearch !== -1) {
                currentSpan.show();
            }
            else {
                currentSpan.hide();
                topixSpansHidden++;
            }
        }
    }

    /*
    Compares entered value with common properties content.
    Increases value for hidden spans to check if there is anything left in the common properties content.
     */
    function setVisibilityForCommonPropertiesSpans(currentSpan, resultOfSearch, value)
    {
        for (var i = 0; i < commonMapSize; i++) {
            currentSpan = $(".property-information > span#common" + i);
            resultOfSearch = currentSpan.text().toLowerCase().search(value);
            if (resultOfSearch !== -1) {
                currentSpan.show();
            }
            else {
                currentSpan.hide();
                commonSpansHidden++;
            }
        }
    }
};
