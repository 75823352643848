(function(arc, $) {

    arc.i18n = {
        messages: $i.i18n.messages
    };

    arc.i18n.get = function (key, args) {
        if (args) {
          return format(arc.i18n.messages[key], args);
        } else {
          return arc.i18n.messages[key];
        }
    };

    arc.i18n.put = function (key, message) {
        arc.i18n.messages[key] = message;
    };

    format = function(message, args) {
        var str = message;
        for (var i = 0; i < args.length; i++) {
            var reg = new RegExp("\\{" + i + "\\}", "gm");
            str = str.replace(reg, args[i]);
        }
        return str;
    };

})(arc, jQuery);
