(function($, arc){

  $(document).ready(function() {

    var sidebar = null;

    $('i#navigation-mobile-trigger').on('click', function() {
        if (sidebar) {
          sidebar.open();
          return false;
        }

        sidebar = new arc.sidebar({
          $target: $('ul.toolbar-navigation'),
          $footer: $('footer').eq(0)
        });
    });

  });

  arc.sidebar = function(options) {
    this.$target = options.$target.clone(true);
    this.$footer = options.$footer.clone(true);

    if (!this.jqueryElExists(this.$target)) {
      return false;
    }

    this.$sidebar = this.create();
    this.$sidebar.on('click', function(e) {
      this.toggle.call(this, e);
    }.bind(this));
  };

  arc.sidebar.prototype.jqueryElExists = function(el) {
    if (!el || !(el instanceof jQuery) || !el.length) {
      return false;
    }

    return true;
  };

  arc.sidebar.prototype.create = function() {
    var self = this,
        $sidebar = $('<div/>').addClass('sidebar'),
        $footer = function() {
          if (this.jqueryElExists(this.$footer)) {
            return this.$footer;
          }

          return '';
        }.bind(this);

    this.$target.each(function() {
      $(this).children().first().addClass('divide');
    });

    $('body').append($sidebar.append($('<ul></ul>').append(this.$target.children()), $footer()));

    // force FF to complete layout before this
    var wait = $sidebar[0].clientHeight;
    setTimeout(function() {
      this.open();
    }.bind(this), 0);

    return $sidebar;
  };

  arc.sidebar.prototype.open = function() {
    this.$sidebar.removeClass('sidebar-closed').addClass('sidebar-open');
  };

  arc.sidebar.prototype.close = function() {
    this.$sidebar.removeClass('sidebar-open');
    setTimeout(function() {
      this.$sidebar.addClass('sidebar-closed');
    }.bind(this), 300);
  };

  arc.sidebar.prototype.toggle = function(e) {
    if (e.offsetX > this.$sidebar.outerWidth()) {
      this.close();
      return;
    }

    this.open();
  };

})(jQuery, arc);
